import { Roles } from '../../../../../domain/konstanten/Roles.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import { displayArray, fuelleSelectOptionen, resetDialogInput, toggleLoader } from '../util.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.confirmAddUserRole = confirmAddUserRole;
window.myHandlers.confirmDelUserRole = confirmDelUserRole;
window.myHandlers.benutzerUpdateBetriebChange = benutzerUpdateBetriebChange;
window.myHandlers.benutzerAddRolleClick = benutzerAddRolleClick;
window.myHandlers.benutzerDelRolleClick = benutzerDelRolleClick;
window.myHandlers.confirmAddUserBereich = confirmAddUserBereich;
window.myHandlers.confirmDelUserBereich = confirmDelUserBereich;
window.myHandlers.benutzerAddBereichClick = benutzerAddBereichClick;
window.myHandlers.benutzerDelBereichClick = benutzerDelBereichClick;
window.myHandlers.confirmNewUserRoleMA = confirmNewUserRoleMA;
window.myHandlers.benutzerNeueRolleClickMA = benutzerNeueRolleClickMA;
window.myHandlers.toggleBenutzerAktiv = toggleBenutzerAktiv;
window.myHandlers.erstelleExternenBenutzer = erstelleExternenBenutzer;
window.myHandlers.confirmNewExternalUser = confirmNewExternalUser;

async function ladeBenutzerverwaltung() {
	toggleLoader();
	const interneExtraUser = await stammdatenService.ladeInterneMitarbeiter(true);
	const externeUser = await stammdatenService.ladeExterneMitarbeiter();
	const internPanel = document.getElementById('bw-intern-panel');
	displayArray(interneExtraUser, 'bw-intern-', internPanel);
	const externPanel = document.getElementById('bw-extern-panel');
	displayArray(externeUser, 'bw-extern-', externPanel);
	toggleLoader();
}

/**
 * Update User Roles Button Click Handler
 * @param {HTMLElement} thisElement Button auf den geklickt wurde
 */
async function benutzerAddRolleClick(thisElement) {
	const userZeile = thisElement.closest('.bw-zeile');
	const userId = userZeile.getAttribute('aria-label');
	let rollen = await stammdatenService.ladeBenutzerRollen();
	rollen = rollen.filter((rolle) => rolle.name !== Roles.Mitarbeiter);
	rollen.sort((a, b) => a.name.localeCompare(b.name));
	const rolleSelect = document.getElementById('benutzer-add-rolle');
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const result = await SystemDialogService.instance.displayAsync('benutzer-rolle-add-dialog');
	if (result.success) {
		await stammdatenService.addBenutzerRolle(result.data.role, userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmAddUserRole() {
	const rolleSelect = document.getElementById('benutzer-add-rolle');
	SystemDialogService.instance.confirm({
		id: rolleSelect.value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText
	});
}

/**
 * Remove User Roles Button Click Handler
 * @param {HTMLElement} thisElement Button auf den geklickt wurde
 */
async function benutzerDelRolleClick(thisElement) {
	const userId = thisElement.closest('.bw-zeile').getAttribute('aria-label');
	let rollen = await stammdatenService.ladeBenutzerRollen();
	rollen = rollen.filter((rolle) => rolle.name !== Roles.Mitarbeiter);
	rollen.sort((a, b) => a.name.localeCompare(b.name));
	const rolleSelect = document.getElementById('benutzer-del-rolle');
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const result = await SystemDialogService.instance.displayAsync('benutzer-rolle-del-dialog');
	if (result.success) {
		await stammdatenService.removeBenutzerRolle(result.data.role, userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmDelUserRole() {
	const rolleSelect = document.getElementById('benutzer-del-rolle');
	SystemDialogService.instance.confirm({
		id: rolleSelect.value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText
	});
}

/**
 * Update User Bereich Button Click Handler
 * @param {HTMLElement} thisElement Button auf den geklickt wurde
 */
async function benutzerAddBereichClick(thisElement) {
	const userZeile = thisElement.closest('.bw-zeile');
	const userId = userZeile.getAttribute('aria-label');
	const bereiche = stammdatenService.unternehmensobjekt.Bereiche;
	const bereichSelect = document.getElementById('benutzer-add-bereich');
	fuelleSelectOptionen(bereichSelect, bereiche, '_id', 'Bereich');
	const result = await SystemDialogService.instance.displayAsync('benutzer-bereich-add-dialog');
	if (result.success) {
		await stammdatenService.addBenutzerBereich(result.data.id, userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmAddUserBereich() {
	const bereichSelect = document.getElementById('benutzer-add-bereich');
	SystemDialogService.instance.confirm({
		id: bereichSelect.value,
		bereich: bereichSelect.options[bereichSelect.selectedIndex].innerText
	});
}

/**
 * Remove User Bereich Button Click Handler
 * @param {HTMLElement} thisElement Button auf den geklickt wurde
 */
async function benutzerDelBereichClick(thisElement) {
	const userId = thisElement.closest('.bw-zeile').getAttribute('aria-label');
	const bereiche = stammdatenService.unternehmensobjekt.Bereiche;
	const bereichSelect = document.getElementById('benutzer-del-bereich');
	fuelleSelectOptionen(bereichSelect, bereiche, '_id', 'Bereich');
	const result = await SystemDialogService.instance.displayAsync('benutzer-bereich-del-dialog');
	if (result.success) {
		await stammdatenService.removeBenutzerBereich(result.data.id, userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmDelUserBereich() {
	const bereichSelect = document.getElementById('benutzer-del-bereich');
	SystemDialogService.instance.confirm({
		id: bereichSelect.value,
		bereich: bereichSelect.options[bereichSelect.selectedIndex].innerText
	});
}

/**
 * Update User Betrieb Handler
 * @param {HTMLElement} thisElement Select, das bedient wurde
 */
async function benutzerUpdateBetriebChange(thisElement) {
	const userId = thisElement.closest('.bw-zeile').getAttribute('aria-label');
	const betriebId = thisElement.value;
	await stammdatenService.updateBenutzerBetrieb(betriebId, userId);
	await ladeBenutzerverwaltung();
}

async function benutzerNeueRolleClickMA() {
	let rollen = await stammdatenService.ladeBenutzerRollen();
	const rolleSelect = document.getElementById('mitarbeiter-neu-rolle');
	rollen = rollen.filter((rolle) => rolle.name !== Roles.Mitarbeiter);
	rollen.sort((a, b) => a.name.localeCompare(b.name));
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const interneUser = await stammdatenService.ladeInterneMitarbeiter(false);
	const maSelect = document.getElementById('mitarbeiter-neu-id');
	fuelleSelectOptionen(maSelect, interneUser, 'id', ['email', 'lastName', 'firstName']);
	const result = await SystemDialogService.instance.displayAsync('mitarbeiter-rolle-neu-dialog');
	if (result.success) {
		await stammdatenService.addBenutzerRolle(result.data.role, result.data.userId);
		await ladeBenutzerverwaltung();
	}
}

function confirmNewUserRoleMA() {
	const rolleSelect = document.getElementById('mitarbeiter-neu-rolle');
	const maSelect = document.getElementById('mitarbeiter-neu-id');
	SystemDialogService.instance.confirm({
		id: rolleSelect.value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText,
		userId: maSelect.value,
	});
}

/**
 * On Input Handler für die Aktiv Checkbox des Benutzers
 * @param {HTMLElement} thisElement
 */
async function toggleBenutzerAktiv(thisElement) {
	const userId = thisElement.closest('.bw-zeile').getAttribute('aria-label');
	const aktivValue = thisElement.checked;
	if (aktivValue) {
		await stammdatenService.reaktivereBenutzer(userId);
		return;
	}
	await stammdatenService.deaktivereBenutzer(userId);
}

async function erstelleExternenBenutzer() {
	let rollen = await stammdatenService.ladeBenutzerRollen();
	// externe Mitarbeiter sollten nicht mitarbeiter werden
	rollen = rollen.filter((rolle) => rolle.name !== Roles.Mitarbeiter);
	rollen.sort((a, b) => a.name.localeCompare(b.name));
	const rolleSelect = document.getElementById('benutzer-neu-role');
	fuelleSelectOptionen(rolleSelect, rollen, 'id', 'name');
	const result = await SystemDialogService.instance.displayAsync('erstelle-benutzer-extern-dialog');
	if (result.success) {
		await stammdatenService.postExternalUser(result.data);
		await ladeBenutzerverwaltung();
		resetDialogInput('erstelle-benutzer-extern-dialog');
	}
}

function confirmNewExternalUser() {
	const rolleSelect = document.getElementById('benutzer-neu-role');
	SystemDialogService.instance.confirm({
		firstName: document.getElementById('benutzer-neu-firstName').value,
		lastName: document.getElementById('benutzer-neu-lastName').value,
		email: document.getElementById('benutzer-neu-email').value,
		role: rolleSelect.options[rolleSelect.selectedIndex].innerText,
	});
}

export {
    ladeBenutzerverwaltung
};
