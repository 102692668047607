import _ from 'underscore';
import basedataService from '../services/basedataService.js';
import stammdatenService from '../services/stammdatenService.js';
import { fuelleSelectOptionen } from '../util.js';
import systemNachrichtService from '../services/systemNachrichtService.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereKrankenkasseUnternehmen = speichereKrankenkasseUnternehmen;

/**
 * Lädt die Auswahl an verwendeten Krankenkassen im Unternehmen
 */
async function ladeKrankenkassen() {
	const updateSuccess = await stammdatenService.fetchKrankenkassenUnternehmen();
	if (!updateSuccess) return;
	
	// Wenn es noch keine Krankenkassen gibt, zeigen wir das an...
	if (stammdatenService.unternehmensobjekt.Krankenkassen.length === 0) {
		systemNachrichtService.zeigeKleineNachricht('Keine Krankenkassen im Unternehmen vorhanden!', 0);
		return;
	}
	document.querySelector('#kk-empty-message').remove();
	// Filtere alle im Unternehmen verwendeten Krankenkassen
	const krankenkassenBasedata = await basedataService.holeKrankenkassenBasedataProvider();
	const krankenkassenBasedataUnternehmen = krankenkassenBasedata.filter((kk) => _.pluck(stammdatenService.unternehmensobjekt.Krankenkassen, 'Betriebsnummer').includes(kk.Betriebsnummer));
	// Sortiere alphabetisch
	krankenkassenBasedataUnternehmen.sort((a, b) => a.Kurzbezeichnung - b.Kurzbezeichnung);
	// Füge für jede Krankenkasse eine Zeile ein...
	const templateZeile = document.querySelector('[kk-zeile-template]');
	for (const krankenkasseBasedata of krankenkassenBasedataUnternehmen) {
		const krankenkasseU = stammdatenService.unternehmensobjekt.Krankenkassen.find((kk) => kk.Betriebsnummer == krankenkasseBasedata.Betriebsnummer);
		const zeile = templateZeile.content.cloneNode(true).children[0];
		zeile.id = krankenkasseU.Betriebsnummer;
		const kkBezeichnung = zeile.querySelector('[aria-label="kk-Kurzbezeichnung"]');
		const betriebsnr = zeile.querySelector('[aria-label="kk-Betriebsnummer"]');
		const u1select = zeile.querySelector('[aria-label="kk-Umlagesatz1"]');
		const u2select = zeile.querySelector('[aria-label="kk-Umlagesatz2"]');
		fuelleSelectOptionen(u1select, krankenkasseBasedata.Umlage1, 'Beitragssatz', ['Beitragssatz', 'Erstattungssatz', 'GueltigAb'], true, '1,00% (Standard)', 1, '%;');
		fuelleSelectOptionen(u2select, krankenkasseBasedata.Umlage2, 'Beitragssatz', ['Beitragssatz', 'Erstattungssatz', 'GueltigAb'], true, '0,39% (Standard)', 0.39, '%;');
		kkBezeichnung.value = krankenkasseBasedata.Kurzbezeichnung;
		betriebsnr.value = krankenkasseU.Betriebsnummer;
		u1select.value = krankenkasseU.Umlagesatz1;
		u2select.value = krankenkasseU.Umlagesatz2;
		templateZeile.parentNode.appendChild(zeile);
	}
}

/**
 * Event Handler für das Auswählen und Speichern eines Umlagesatzes
 * @param {HTMLElement} thisElement Select des Umlagesatzes
 */
async function speichereKrankenkasseUnternehmen(thisElement) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const zeile = thisElement.parentNode;
	const betriebsnummer = zeile.id;
	const krankenkasseU = unternehmen.Krankenkassen.find((kk) => kk.Betriebsnummer === betriebsnummer);
	const u1select = zeile.querySelector('[aria-label="kk-Umlagesatz1"]');
	const u2select = zeile.querySelector('[aria-label="kk-Umlagesatz2"]');
	krankenkasseU.Umlagesatz1 = parseFloat(u1select.value);
	krankenkasseU.Umlagesatz2 = parseFloat(u2select.value);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
}

export {
    ladeKrankenkassen
};
