import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayArray, neueArrayRow, resetDialogInput } from '../util.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.confirmNeueQualifikationDialog = confirmNeueQualifikationDialog;
window.myHandlers.neueQualifikation = neueQualifikation;
window.myHandlers.speichereQuali = speichereQuali;

async function ladeQualifikationen(unternehmen) {
	displayArray(unternehmen.Qualifikationen, 'qu-');
}

function confirmNeueQualifikationDialog() {
	SystemDialogService.instance.confirm({
		BezeichnungNeutral: document.getElementById('neu-quali-neutral').value,
		BezeichnungWeiblich: document.getElementById('neu-quali-female').value,
		BezeichnungMaennlich: document.getElementById('neu-quali-male').value,
		Qualifikationsnummer: parseInt(document.getElementById('neu-quali-number').value, 10),
	});
}

// Click handler von neue Netto Be/Abzüge anlegen
async function neueQualifikation(prefix) {
	// Öffnen und Warten auf Input Dialog...
	const input = await SystemDialogService.instance.displayAsync('neu-quali-dialog');
	// vermeide leere Einträge
	if (!input.success) {
		return;
	}
	if (input.success && input.data.BezeichnungNeutral === '') {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung angegeben.', '0');
		return;
	}
	// Wir schicken den neuen Bezug an den Server
	const response = await stammdatenService.erstelleQualifikation(input.data);
	if (!response) {
		return;
	}
	resetDialogInput('neu-quali-dialog');
	// Aktualisierung der Oberfläche
	const template = document.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = document.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, response, prefix);
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereQuali(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (qu-) aus.
	const zeileID = zeile.id.split('-')[1];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const quali = unternehmen.Qualifikationen.find((qu) => qu._id === zeileID);
	if (!quali) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	collectValues(quali, 'qu-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

export {
	ladeQualifikationen
};
