/**
 * Hier kommt Code für die Mitarbeiter Zeiteneingabe hin
 */

import _, { filter } from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';
import basedataService from '../services/basedataService.js';
import stammdatenService from '../services/stammdatenService.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import {
	checkUrlaubsantragKollisionen,
    fuelleSelectOptionen,
    holePostenMitarbeiter,
    holeEinsatzorteMitarbeiter,
    holeQualifikationenMitarbeiter,
	formatNumber2Decimals,
	ObservableArray,
} from '../util.js';
import { feiertagFilter } from '../../../../util/util.js';
import schichtService from '../services/schichtService.js';
import { Egfz, EgfzBezahlt } from '../../../../shared/services/Egfz.js';

// Globale Event Listener einbinden
window.myVars = window.myVars || {};
window.myHandlers = window.myHandlers || {};
window.myHandlers.pickDate = pickDate;
window.myHandlers.neuePause = neuePause;
window.myHandlers.inputNavigation = inputNavigation;
window.myHandlers.berechneBezahlteZeit = berechneBezahlteZeit;
window.myHandlers.resetKeysPressedCount = resetKeysPressedCount;
window.myHandlers.berechneEgfzTageAnzahl = berechneEgfzTageAnzahl;
window.myHandlers.inputEgfzTage = inputEgfzTage;
window.myHandlers.jumpNext = jumpNext;
window.myHandlers.showRows = showRows;
window.myHandlers.selectTag = selectTag;
window.myHandlers.inputZeiten = inputZeiten;
window.myHandlers.leseRegelarbeitszeiten = leseRegelarbeitszeiten;
window.myHandlers.monatlicheGutzeitMA = monatlicheGutzeitMA;

let zeitenAnsicht = 2;
let schichtSelected = null;

let keysPressedCount = 0;
let oldFocus = null;
let schichtenIndex = null;
let deleteStartIndex = null;
let feiertageBundesland = [];
const zeitenMonat = ObservableArray();
// Example callback function
zeitenMonat.setCallback((zeitenNeu) => berechneSummen(zeitenNeu));

// Führt den initialen Aufruf der Zeitenoberfläche auf und befüllt sie mit den aktuellen Daten.
async function zeitenScript(autoSelect = true) {
	if (_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
		return;
	}
	// Mitarbeiter daten refreshen für mögliche Änderungen im Urlaubsantrag usw.
	await stammdatenService.holeMitarbeiter(stammdatenService.aktuellerMitarbeiter._id);
	const zeitenBody = document.getElementById('zeiten-body');
	const betriebsstaetteID = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].BetriebsstaetteID;
	if (!betriebsstaetteID || betriebsstaetteID === '') {
		const dialogText = 'Dieser Mitarbeiter ist keiner Betriebsstätte zugeordnet! Bitte gehen Sie zum Reiter Beschäftigung und wählen Sie eine aus.';
		document.getElementById('zeiten-alert-text').innerText = dialogText;
		await SystemDialogService.instance.displayAsync('zeiten-alert-dialog');
		return;
	}
	const aktuelleBS = stammdatenService.unternehmensobjekt.Betriebsstaette.find((bs) => bs._id === betriebsstaetteID);
	const feiertage = await basedataService.holeFeiertageBasedataProvider();
	feiertageBundesland = feiertagFilter(feiertage, aktuelleBS.Bundesland, aktuelleBS.Feiertage);
	const egfz = await basedataService.holeEgfzBasedataProvider();
	const qualiSelect = zeitenBody.querySelector('[aria-label="zeiten-QualifikationID"]');
	const einsatzortSelect = zeitenBody.querySelector('[aria-label="zeiten-EinsatzortID"]');
	const postenSelect = zeitenBody.querySelector('[aria-label="zeiten-PostenID"]');
	const efgzSelect = zeitenBody.querySelector('[aria-label="zeiten-Egfz"]');
	const qualisMA = holeQualifikationenMitarbeiter();
	const postenMA = holePostenMitarbeiter();
	const einsatzorteMA = holeEinsatzorteMitarbeiter();
	const seenQualis = {}; // Object to keep track of seen qualifications 
	const filteredQualis = qualisMA.filter((qu) => {
		const id = qu.QualifikationID;
		if (!seenQualis[id]) {
			seenQualis[id] = true;
			return true; // Keep the object if it's the first occurrence of this qualification
		}
		return false; // Filter out objects with the same qualification
	});
	// Standardquali als erstes
	filteredQualis.sort((a, b) => a?.IstStandardQualifikation == b?.IstStandardQualifikation ? 0 : a ? -1 : 1);
	// Input Control bestücken
	fuelleSelectOptionen(qualiSelect, filteredQualis, 'QualifikationID', 'Bezeichnung');
	fuelleSelectOptionen(postenSelect, postenMA, 'PostenID', 'Bezeichnung');
	fuelleSelectOptionen(einsatzortSelect, einsatzorteMA, 'EinsatzortID', 'Bezeichnung');
	// Wenn es Einsatzorte gibt, dann den ersten automatisch auswählen
	if (einsatzorteMA.length > 1) einsatzortSelect.value = einsatzortSelect.options[1].value;
	fuelleSelectOptionen(efgzSelect, egfz, 'EGFZID', 'Bezeichnung');
	// Umsatz Input zurücksetzen und nur anzeigen falls im Mitarbeiter eine Umsatzentlohnung aktiviert ist.
	zeitenBody.querySelector('[aria-label="zeiten-Umsatz"]').value = 0;
	if (stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Verguetung.some((quali) => ['umsatzorientierterStundenlohn', 'Umsatzanteilslohn'].includes(quali.Entlohnung) && quali.Verwenden)) {
		zeitenBody.querySelector('#zeiten-input-umsatz').style.display = 'flex';
	} else {
		zeitenBody.querySelector('#zeiten-input-umsatz').style.display = 'none';
	}

	const list = zeitenBody.querySelector('#zeiten-liste');
	list.innerHTML = '';
	const abrechnungstag = stammdatenService.unternehmensobjekt.Abrechnungstag;
	let startOfMonth = window.myVars.aktuellesMonatsDatum.startOf('month');
	let endOfMonth = window.myVars.aktuellesMonatsDatum.endOf('month');
	let daysInMonth = window.myVars.aktuellesMonatsDatum.daysInMonth();
	// Wenn das Unternehmen ein Abrechnungstag früher hat, dann müssen wir die Zeiten anders anzeigen.
	// Zum Beispiel: Abrechnungstag ist er 15., dann sollte man alle Zeiten vom 16. des Vormonats bis zum 15. des aktuellen Monats anzeigen.
	if (abrechnungstag < 31) {
		startOfMonth = startOfMonth.subtract(1, 'month').add(abrechnungstag, 'days');
		endOfMonth = endOfMonth.startOf('month').add(abrechnungstag - 1, 'days');
		daysInMonth = endOfMonth.diff(startOfMonth, 'day') + 1;
	}
	let dayCounter = startOfMonth.clone();
	// Erstelle die Oberfläche für jeden Tag im Monat...
	const urlaubsantraege = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Urlaubsantraege;
	for (let i = 1; i <= daysInMonth; i += 1) {
		const item = document.createElement('div');
		item.setAttribute('aria-label', dayCounter.format('YYYY-MM-DD'));
		item.addEventListener('click', async (event) => {
			await selectTag(item, event);
		});
		item.classList.add('zeiten-list-item');
		if (dayCounter.day() === 0) {
			item.classList.add('zeiten-sonntag');
		}
		// eslint-disable-next-line no-loop-func
		const isFeiertag = feiertageBundesland.find((feiertag) => dayjs(feiertag.date).isSame(dayCounter));
		if (isFeiertag) {
			item.classList.add('zeiten-feiertag');
			item.title = isFeiertag.fname;
		}
		const isNotActive = dayCounter.isBefore(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Eintrittsdatum) || dayCounter.isAfter(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Austrittsdatum);
		if (isNotActive) {
			item.style.opacity = '0.5';
		}
		// KW hinzufügen
		const kalWoche = document.createElement('div');
		kalWoche.classList.add('zeiten-kw');
		kalWoche.innerText = dayCounter.isoWeek();
		item.appendChild(kalWoche);
		// Tag hinzufügen
		const tag = document.createElement('div');
		tag.classList.add('zeiten-tag');

		tag.innerHTML = `<span>${dayCounter.format('dd')}.</span><span> ${dayCounter.format('DD.MM.')}</span>`;
		item.appendChild(tag);

		// Urlaub anzeigen (verhindert schicht hinzufügen)
		if (!_.isEmpty(urlaubsantraege)) {
			const hatUrlaub = urlaubsantraege.some((ua) => dayCounter.isBetween(ua.UrlaubVon, ua.UrlaubBis, 'day', '[]') && ua.UrlaubStatus === 'genehmigt');
			if (hatUrlaub) {
				const urlaubIcon = document.createElement('i');
				urlaubIcon.className = 'bi bi-airplane-fill tag-urlaub';
				urlaubIcon.title = 'Mitarbeiter ist im Urlaub!';
				item.appendChild(urlaubIcon);
			}
		}

		list.appendChild(item);
		dayCounter = dayCounter.add(1, 'day');
	}

	// Lade alle Schichten des Monats des Mitarbeiters und zeige sie an...
	const zeiten = await schichtService.leseAlleSchichtenMitarbeiter(startOfMonth.startOf('day').toDate(), endOfMonth.endOf('day').toDate(), stammdatenService.aktuellerMitarbeiter._id);
	zeitenMonat.set(zeiten);
	// async forEach macht hier Probleme...
	for (const zeit of zeitenMonat.get()) {
		await erstelleSchichtElement(zeit, true);
	}

	// Füge Anfangswerte und HTML Validierungen ein max und min date für Date Picker ein:
	const egfzVonInput = document.querySelector('[aria-label="egfz-von"]');
	egfzVonInput.value = startOfMonth.format('YYYY-MM-DD');
	egfzVonInput.min = startOfMonth.format('YYYY-MM-DD');
	egfzVonInput.max = endOfMonth.format('YYYY-MM-DD');
	const egfzBisInput = document.querySelector('[aria-label="egfz-bis"]');
	egfzBisInput.value = startOfMonth.format('YYYY-MM-DD');
	egfzBisInput.min = startOfMonth.format('YYYY-MM-DD');
	egfzBisInput.max = endOfMonth.format('YYYY-MM-DD');
	const datumInput = document.body.querySelector('#zeiten-datum-input');
	datumInput.min = startOfMonth.format('YYYY-MM-DD');
	datumInput.max = endOfMonth.format('YYYY-MM-DD');

	// Pausenmodell aktiv?
	document.getElementById('zeiten-pausenmodell-aktiv').checked = !_.isEmpty(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Pausenmodell);
}

async function berechneSummen(zeiten = []) {
	if (_.isEmpty(zeiten)) {
		const abrechnungstag = stammdatenService.unternehmensobjekt.Abrechnungstag;
		let startOfMonth = window.myVars.aktuellesMonatsDatum.startOf('month');
		let endOfMonth = window.myVars.aktuellesMonatsDatum.endOf('month');
		// Wenn das Unternehmen ein Abrechnungstag früher hat, dann müssen wir die Zeiten anders anzeigen.
		// Zum Beispiel: Abrechnungstag ist er 15., dann sollte man alle Zeiten vom 16. des Vormonats bis zum 15. des aktuellen Monats anzeigen.
		if (abrechnungstag < 31) {
			startOfMonth = startOfMonth.subtract(1, 'month').add(abrechnungstag, 'days');
			endOfMonth = endOfMonth.startOf('month').add(abrechnungstag - 1, 'days');
		}
		zeiten = await schichtService.leseAlleSchichtenMitarbeiter(startOfMonth.startOf('day').toDate(), endOfMonth.endOf('day').toDate(), stammdatenService.aktuellerMitarbeiter._id);
	}
	// initial alles auf 0
	let summeArbeit = 0, summeBezahlt = 0, summeGesamt = 0, summeGZ = 0, summeKrank = 0, summeUrlaub = 0, summeArbeitTage = 0, summeGesamtTage = 0;
	let lastArbeitstag = new Date(0);
	let lastUrlaubstag = new Date(0);
	let lastKranktag = new Date(0);
	let lastGesamttag = new Date(0);
	zeiten.forEach((zeit) => {
		if (zeit.Bis === '') {
			return;
		}
		if (_.values(EgfzBezahlt).includes(zeit.Egfz)) {
			summeBezahlt += zeit.BezahlteStunden;
			// unbezahlte Pausen/Unterbrechungen sollen nicht in die Gesamtstunden...
			summeGesamt += zeit.BezahlteStunden;
		} else {
			// unbezahlte EGFZ Arten kommen in die Gesamtstunden...
			summeGesamt += zeit.GesamtStunden - zeit.PauseUnbezahltStunden;
		}
		if (zeit.Egfz === Egfz.Arbeit) {
			summeArbeit += zeit.BezahlteStunden;
			if (dayjs(lastArbeitstag).isBefore(zeit.Datum, 'day')) {
				summeArbeitTage += 1;
				lastArbeitstag = zeit.Datum;
			}
		}
		if (zeit.Egfz === Egfz.GZAufbau) {
			// GZ Aufbau kann bezahlte Pausen enthalten...
			summeBezahlt += zeit.BezahlteStunden;
			summeGZ += zeit.GesamtStunden - zeit.PauseUnbezahltStunden;
		}
		if (zeit.Egfz === Egfz.GZAbbau) {
			summeGZ -= zeit.GesamtStunden;
		}
		if (zeit.Egfz === Egfz.Urlaub && dayjs(lastUrlaubstag).isBefore(zeit.Datum, 'day')) {
			summeUrlaub += 1;
			lastUrlaubstag = zeit.Datum;
		}
		if ([Egfz.Krank, Egfz.KindKrank, Egfz.Krank6Wochen, Egfz.Krank6Wochen, Egfz.KrankKurzarbeit, Egfz.UnentschuldigtKrank].includes(zeit.Egfz) && dayjs(lastKranktag).isBefore(zeit.Datum, 'day')) {
			summeKrank += 1;
			lastKranktag = zeit.Datum;
		}
		if (dayjs(lastGesamttag).isBefore(zeit.Datum, 'day')) {
			summeGesamtTage += 1;
			lastGesamttag = zeit.Datum;
		}
	});
	const summenZeile = document.getElementById('zeiten-liste-summe');
	const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
	summenZeile.querySelector('#ma-zeiten-summe-arbeit').innerText = formatNumber2Decimals(summeArbeit);
	summenZeile.querySelector('#ma-zeiten-summe-bezahlt').innerText = formatNumber2Decimals(summeBezahlt);
	summenZeile.querySelector('#ma-zeiten-summe-gesamt').innerText = formatNumber2Decimals(summeGesamt);
	summenZeile.querySelector('#ma-zeiten-summe-soll').innerText = formatNumber2Decimals(aktuellerMA.Beschaeftigung[0].SollstundenMonat);
	summenZeile.querySelector('#ma-zeiten-summe-gutzeit').innerText = formatNumber2Decimals(summeGZ);
	summenZeile.querySelector('#ma-zeiten-summe-arbeitstage').innerText = summeArbeitTage;
	summenZeile.querySelector('#ma-zeiten-summe-krank').innerText = summeKrank;
	summenZeile.querySelector('#ma-zeiten-summe-urlaub').innerText = summeUrlaub;
	summenZeile.querySelector('#ma-zeiten-summe-gesamttage').innerText = summeGesamtTage;
}

/**
 * Klappt eine Zeile der Zeitblöcke ein
 * @param {int} rowNumber Nummer der Zeile, die zu verstecken gilt.
 */
function showRows(rowNumber, event = undefined) {
	const liste = document.body.querySelector('#zeiten-liste');
	const checkboxOben = document.getElementById('zeiten-legende-erste-zeile');
	const checkboxUnten = document.getElementById('zeiten-legende-zweite-zeile');
	// Setze beide Häkchen wieder, falls beide Häkchen rausgenommen wurden
	if (!checkboxOben.checked && !checkboxUnten.checked && event) {
		event.preventDefault();
		return;
	}
	// Resette alle Zeilen
	const allRows = liste.querySelectorAll('.zeiten-item');
	allRows.forEach((element) => {
		if (!element.classList.contains('hidden')) {
			element.style.display = 'flex';
		}
	});
	zeitenAnsicht = 0;
	// Verstecke eine Zeile falls nur ein Häkchen rausgenommen wurde...
	if (rowNumber > 0 && (!checkboxOben.checked || !checkboxUnten.checked)) {
		const rows = liste.querySelectorAll(`[aria-rowindex='${rowNumber}'`);
		rows.forEach((element) => {
			element.style.display = 'none';
		});
		zeitenAnsicht = rowNumber;
	}
}

/**
 * Wenn man einen Tag bzw. Schicht in der Liste auswählt, soll das Datum und die Eingaben im Input erscheinen
 * @param {HTMLElement} thisElement das HTML Element auf das geklickt wurde
 * @param {*} event
 */
async function selectTag(thisElement, event = null) {
	if (event !== null) event.stopPropagation();
	if (event !== null && event.ctrlKey === true && window.myVars.zeitenEditable) {
		removeSchichtSelection();
		if (thisElement.classList.contains('zeiten-grid')) {
			if (thisElement.classList.contains('zeiten-item-loeschen')) {
				thisElement.classList.remove('zeiten-item-loeschen');
				resetDeleteIndices();
			} else {
				thisElement.classList.add('zeiten-item-loeschen');
				const schichten = Array.from(document.querySelectorAll('.zeiten-grid:not(#zeiten-grid-legende)'));
				schichtenIndex = schichten.findIndex((s) => s.id === thisElement.id);
				deleteStartIndex = schichtenIndex;
			}
		}
	} else {
		// Wenn ohne CTRL geklickt wurde, wird die lösch Selektion aufgehoben
		unmarkiereSchichtenLoeschen();
		const datePicker = document.body.querySelector('#zeiten-datum-input');
		datePicker.value = thisElement.getAttribute('aria-label');
		// entferne die alte visuelle Selection, falls es eine gibt
		removeTagSelection();
		removeSchichtSelection();
		if (thisElement.classList.contains('zeiten-grid')) {
			await selectSchicht(thisElement);
		} else {
			thisElement.classList.add('tag-selected');
			leseRegelarbeitszeiten(datePicker.value);
			document.querySelector('[aria-label="zeiten-Von"]').focus();
		}
	}
}

/**
 * Füllt die von und bis Zeit aus mit den Regelarbeitszeiten/Sollstunden
 * @param {string} datum tagesdatum
 */
function leseRegelarbeitszeiten(datum = document.getElementById('zeiten-datum-input').value) {
	// Nehme die Uhrzeiten aus der Regelarbeitszeit, falls vorhanden.
	const regelarbeitszeitenMA = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Regelarbeitszeiten;
	const tagIndex = dayjs(datum).day() - 1;
	const egfzAuswahl = document.querySelector('[aria-label="zeiten-Egfz"]').value;
	// GZ Aufbau wird wie Arbeitszeit behandelt.
	const egfzArbeit = egfzAuswahl == Egfz.Arbeit || egfzAuswahl == Egfz.GZAufbau;
	const vonHtml = document.querySelector('[aria-label="zeiten-Von"]');
	const bisHtml = document.querySelector('[aria-label="zeiten-Bis"]');
	// Wenn wir Arbeitsschichten wählen, dann fügen wir die Regelarbeitszeiten ein (falls keine Schicht selektiert wurde)
	if (!schichtSelected && egfzArbeit && regelarbeitszeitenMA[tagIndex < 0 ? 6 : tagIndex].Von !== null && regelarbeitszeitenMA[tagIndex < 0 ? 6 : tagIndex].Bis !== null) {
		vonHtml.value = regelarbeitszeitenMA[tagIndex < 0 ? 6 : tagIndex].Von;
		bisHtml.value = regelarbeitszeitenMA[tagIndex < 0 ? 6 : tagIndex].Bis;
	}
	// Wenn wir eine egfz Schicht haben, die nicht Arbeit/GZ+/GZ- ist, dann fügen wir die Sollstunden ein.
	if (!egfzArbeit && egfzAuswahl != Egfz.GZAbbau) {
		vonHtml.value = regelarbeitszeitenMA[tagIndex < 0 ? 6 : tagIndex].Von ? regelarbeitszeitenMA[tagIndex < 0 ? 6 : tagIndex].Von : '08:00';
		bisHtml.value = dayjs(`${datum} ${vonHtml.value}`, 'YYYY-MM-DD HH:mm').add(stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].SollstundenTag, 'hours').format('HH:mm');
		// wir müssen auch die Pausen entfernen, falls vorhanden
		const pausenInputHtml = document.body.querySelectorAll('[aria-label="zeiten-Pausen"]');
		pausenInputHtml.forEach((pauseHtml) => pauseHtml.remove());
	}
	// Falls wir GZ- haben, wollen wir keine Pausen, bisherige Zeitangaben bleiben bestehen
	if (egfzAuswahl == Egfz.GZAbbau) {
		// wir müssen auch die Pausen entfernen, falls vorhanden
		const pausenInputHtml = document.body.querySelectorAll('[aria-label="zeiten-Pausen"]');
		pausenInputHtml.forEach((pauseHtml) => pauseHtml.remove());
	}
	berechneBezahlteZeit();
}

/**
 * Nach Wählen eines Datums, soll der Tag markiert werden in der Liste
 * @param {HTMLElement} thisElement
 */
async function pickDate(thisElement) {
	try {
		removeTagSelection();
		removeSchichtSelection();
		const datum = thisElement.value;
		const listItem = document.body.querySelector(`[aria-label='${datum}'].zeiten-list-item`);
		listItem.classList.add('tag-selected');
	} catch (error) {
		console.log(error);
		const dialogText = `Geben Sie Bitte ein Datum des aktuell gewählten Monats ein!${thisElement.value}`;
		systemNachrichtService.zeigeKleineNachricht(dialogText, -1);
	}
}

/**
 * Eingabe von Schichtdaten einer bestehenden oder neuen Schicht
 * @param {bool} extra true => neue Schicht am gleichen Tag, false => Schicht editieren falls ausgewählt
 * @returns
 */
async function inputZeiten(extra) {
	if (extra) {
		schichtSelected = null;
	}
	const inputObject = sammleZeitenInput();
	const maEintritt = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Eintrittsdatum;
	const maAustritt = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Austrittsdatum;
	// Ohne Eintrittsdatum wird kein Schichteninput erlaubt...
	if (_.isEmpty(maEintritt)) {
		systemNachrichtService.zeigeKleineNachricht('Bitte erst das Eintrittsdatum der Beschäftigung festlegen!', 0);
		return;
	}
	// Falls der user eine Schicht vor dem Eintrittsdatum des Mitarbeiters eingeben will, wird dies geblockt.
	if (dayjs(inputObject.Datum).isBefore(maEintritt)) {
		systemNachrichtService.zeigeKleineNachricht('Schichtdatum darf nicht vor dem Eintrittsdatum liegen!', -1);
		return;
	}
	// Falls der user eine Schicht nach dem Austrittsdatum des Mitarbeiters eingeben will, wird dies geblockt.
	if (maAustritt && dayjs(inputObject.Datum).isAfter(dayjs(maAustritt).endOf('day'))) {
		systemNachrichtService.zeigeKleineNachricht('Schichtdatum darf nicht nach dem Austrittsdatum liegen!', -1);
		return;
	}

	if (inputObject === null) {
		return;
	}
	// Speichere input Daten in die Datenbank bevor sie angezeigt werden
	inputObject.MitarbeiterID = stammdatenService.aktuellerMitarbeiter._id;
	inputObject._id = schichtSelected === null ? undefined : schichtSelected;
	const result = await schichtService.speichereSchicht(inputObject);
	if (!result) {
		return;
	}
	const schichtIndex = zeitenMonat.get().findIndex((zeit) => zeit._id === result._id);
	if (schichtIndex >= 0) {
		zeitenMonat.replace(schichtIndex, result);
	} else {
		zeitenMonat.push(result);
	}
	if (schichtSelected === null) {
		// Neuen Balken erstellen
		await erstelleSchichtElement(result);
	} else {
		// Alten balken editieren
		await editiereSchicht(result);
	}
	if (result.Egfz == 2) {
		const gridItem = document.getElementById(result._id);
		// Falls es noch kein Flieger Symbol gibt, tragen wir es nach
		if (!gridItem.parentNode.querySelector('.tag-urlaub')) {
			const urlaubIcon = document.createElement('i');
			urlaubIcon.className = 'bi bi-airplane-fill tag-urlaub';
			urlaubIcon.title = 'Mitarbeiter ist im Urlaub!';
			gridItem.insertAdjacentElement('beforebegin', urlaubIcon);
		}
	}
	toggleUeberlaengeSymbol(inputObject.MitarbeiterID);
	document.querySelector('[aria-label="zeiten-Von"]').focus();
	unmarkiereSchichtenLoeschen();
}

/**
 * Kontrolliert ob es in den Zeiten Schichten mit markierter Überlänge gibt.
 * - bei ja: Alarmuhr erscheint in Mitarbeiter Liste
 * - bei nein: Alarmuhr ausblenden in der Mitarbeiter Liste
 * @param {string} mitarbeiterID 
 */
function toggleUeberlaengeSymbol(mitarbeiterID) {
	if (document.getElementById('zeiten-liste').querySelectorAll('.ueberlaenge').length > 0) {
		document.getElementById(mitarbeiterID).querySelector('.ma-ueberlaenge-icon').classList.remove('hidden');
	} else {
		document.getElementById(mitarbeiterID).querySelector('.ma-ueberlaenge-icon').classList.add('hidden');
	}
}

// Sammelt die eingebenen Daten aus der Oberfläche und erstellt ein InputObjekt
function sammleZeitenInput() {
	try {
		// Merke den Input Cointainer, um die verschiedenen Inputfelder auszulesen
		const zeitenInputBody = document.body.querySelector('#zeiten-input-body');
		const inputObject = {};
		inputObject.Datum = zeitenInputBody.querySelector('#zeiten-datum-input').value;
		if (inputObject.Datum === '') {
			const dialogText = 'Bitte ein Datum auswählen!';
			systemNachrichtService.zeigeKleineNachricht(dialogText, -1);
			return null;
		}
		inputObject.Datum = dayjs(inputObject.Datum);

		const vonStr = zeitenInputBody.querySelector('[aria-label="zeiten-Von"]').value;
		const bisStr = zeitenInputBody.querySelector('[aria-label="zeiten-Bis"]').value;
		if (vonStr === '' || bisStr === '') {
			const dialogText = 'Bitte von und bis Zeiten angeben!';
			systemNachrichtService.zeigeKleineNachricht(dialogText, -1);
			return null;
		}
		inputObject.Von = dayjs(`${inputObject.Datum.format('YYYY-MM-DD')}T${vonStr}`);
		inputObject.Bis = dayjs(`${inputObject.Datum.format('YYYY-MM-DD')}T${bisStr}`);
		// Das Datum wird gleich gesetzt mit dem Von Datum, um keine Schwierigkeiten bei Tageswechsel zwischen Client und Server Uhrzeit zu bekommen.
		inputObject.Datum = inputObject.Von;
		if (inputObject.Bis.diff(inputObject.Von) < 0) {
			const nextDay = inputObject.Von.add(1, 'day');
			// wegen der Zeitumstellung können wir nicht einfach einen tag addieren...
			inputObject.Bis = dayjs(`${nextDay.format('YYYY-MM-DD')}T${bisStr}`);
		}

		inputObject.Egfz = parseInt(zeitenInputBody.querySelector('[aria-label="zeiten-Egfz"]').value, 10);
		inputObject.Titel = basedataService.egfz.find((e) => e.EGFZID === inputObject.Egfz).BezeichnungKurz;
		inputObject.Notiz = zeitenInputBody.querySelector('[aria-label="zeiten-Notiz"]').value;

		const qualiSelect = zeitenInputBody.querySelector('[aria-label="zeiten-QualifikationID"]');
		const einsatzortSelect = zeitenInputBody.querySelector('[aria-label="zeiten-EinsatzortID"]');
		const postenSelect = zeitenInputBody.querySelector('[aria-label="zeiten-PostenID"]');
		if (qualiSelect.options.length <= 0) {
			const dialogText = 'Mitarbeiter besitzt keine Qualifikation!';
			systemNachrichtService.zeigeKleineNachricht(dialogText, -1);
			return null;
		}
		if (postenSelect.options.length <= 0) {
			const dialogText = 'Mitarbeiter ist keinem Posten zugeordnet!';
			systemNachrichtService.zeigeKleineNachricht(dialogText, -1);
			return null;
		}
		inputObject.Qualifikation = qualiSelect.options[qualiSelect.selectedIndex].innerText;
		inputObject.Posten = postenSelect.options[postenSelect.selectedIndex].innerText;
		inputObject.Einsatzort = einsatzortSelect.options[einsatzortSelect.selectedIndex].innerText;
		inputObject.QualifikationID = qualiSelect.value;
		inputObject.PostenID = postenSelect.value;
		inputObject.EinsatzortID = einsatzortSelect.value;
		inputObject.BetriebsstaetteID = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].BetriebsstaetteID;
		// UrlaubID mitaufnehmen, falls vorhanden...
		if (schichtSelected !== null) {
			const schichtAlt = document.getElementById(schichtSelected);
			const urlaubID = schichtAlt.querySelector('[aria-label="UrlaubID"]').innerText;
			if (urlaubID !== '') {
				inputObject.UrlaubID = urlaubID;
			}
		}

		const pausenElemente = zeitenInputBody.querySelectorAll('[aria-label="zeiten-Pausen"]');
		inputObject.Pausen = lesePausen(pausenElemente, inputObject.Datum);
		validierePausen(inputObject);
		const pausenZeiten = berechnePausenZeit(inputObject.Datum);
		inputObject.Pausenzeit = pausenZeiten.PauseGesamt;
		inputObject.Gesamtzeit = dayjs.duration(inputObject.Bis.diff(inputObject.Von));
		inputObject.Bezahltezeit = inputObject.Gesamtzeit.$ms - pausenZeiten.PauseUnbezahlt.$ms;
		inputObject.Bezahltezeit = dayjs.duration(inputObject.Bezahltezeit).format('HH:mm');
		inputObject.Pausenzeit = inputObject.Pausenzeit.format('HH:mm');
		inputObject.Gesamtzeit = inputObject.Gesamtzeit.format('HH:mm');
		inputObject.Umsatz = parseFloat(zeitenInputBody.querySelector('[aria-label="zeiten-Umsatz"]').value);
		return inputObject;
	} catch (error) {
		systemNachrichtService.zeigeKleineNachricht('Unvollständige Inputdaten vorhanden!', -1);
		console.log(error);
		throw new Error('Invalider User Input');
	}
}

// checkt ob die Pausen im schichtObjekt valide sind zum Speichern
function validierePausen(schichtObjekt) {
	let isValid = true;
	let hatUeberschneidung = false;
	schichtObjekt.Pausen = schichtObjekt.Pausen.filter((pause) => {
		// Pausen müssen innerhalb der Schicht liegen:
		isValid = isValid && dayjs(pause.Von).isSameOrAfter(dayjs(schichtObjekt.Von));
		isValid = isValid && dayjs(pause.Bis).isAfter(dayjs(pause.Von));
		isValid = isValid && dayjs(schichtObjekt.Bis).isSameOrAfter(dayjs(pause.Bis));
		// falls wir eine nicht valide Pause haben, checken wir ob es eine Überschneidung gibt.
		// Pausen, die komplett außerhalb der Schicht liegen, können wir herausgefiltert lassen...
		if (!isValid) {
			hatUeberschneidung = dayjs(schichtObjekt.Von).isBetween(pause.Von, pause.Bis, 'minutes', '()');
			hatUeberschneidung = hatUeberschneidung || dayjs(schichtObjekt.Bis).isBetween(pause.Von, pause.Bis, 'minutes', '()');
		}
		return isValid;
	});
	if (hatUeberschneidung) {
		systemNachrichtService.zeigeKleineNachricht('Pausen müssen innerhalb der Schicht liegen!', -1);
		throw new Error('Pausen sind nicht valide!');
	}
}

// Rendert ein neues Schichten Element für die Oberfläche aus dem gebenen inputObjekt
async function erstelleSchichtElement(inputObject, initialLoad = false) {
	// Tag Element in der Liste finden
	if (!dayjs.isDayjs(inputObject.Datum)) {
		inputObject.Datum = dayjs(inputObject.Datum);
	}
	const abrechnungstag = stammdatenService.unternehmensobjekt.Abrechnungstag;
	let startOfMonth = window.myVars.aktuellesMonatsDatum.startOf('month');
	let endOfMonth = window.myVars.aktuellesMonatsDatum.endOf('month');
	// Wenn das Unternehmen ein Abrechnungstag früher hat, dann müssen wir die Zeiten anders anzeigen.
	// Zum Beispiel: Abrechnungstag ist er 15., dann sollte man alle Zeiten vom 16. des Vormonats bis zum 15. des aktuellen Monats anzeigen.
	if (abrechnungstag < 31) {
		startOfMonth = startOfMonth.subtract(1, 'month').add(abrechnungstag, 'days');
		endOfMonth = endOfMonth.startOf('month').add(abrechnungstag - 1, 'days');
	}
	// Render nur Elemente, die dem aktuellen Monat zuegordnet sind! Early return falls nicht.
	if (!inputObject.Datum.isBetween(startOfMonth, endOfMonth, 'month', '[]')) {
		return;
	}
	// Laden des Templates für den Balken
	const template = document.body.querySelector('[zeiten-grid-template]');
	const queryStr = `[aria-label='${inputObject.Datum.format('YYYY-MM-DD')}'].zeiten-list-item`;
	const listItem = document.body.querySelector(queryStr);
	// Schichtblock erstellen
	const neuerBalken = template.content.cloneNode(true).children[0];
	neuerBalken.id = inputObject._id;
	neuerBalken.setAttribute('aria-label', inputObject.Datum.format('YYYY-MM-DD'));
	if (inputObject.Egfz === Egfz.GZAbbau) {
		neuerBalken.classList.add('egfz-gzminus');
	}
	if (inputObject.Egfz === Egfz.GZAufbau) {
		neuerBalken.classList.add('egfz-gzplus');
	}
	// Schichblock dem Tag hinzufügen
	listItem.appendChild(neuerBalken);
	await fuelleSchichtBalken(inputObject);
	showRows(zeitenAnsicht);
	if (!initialLoad) {
		// springe zum nächsten Tag...
		await springeZeile();
	}
}

// Navigationshilfe für die Navigation durch Pfeiltasten innerhalb der Zeitenliste
async function springeZeile(richtung = 1) {
	const monatstageListe = [...document.querySelectorAll('.zeiten-list-item')];
	const currentIndex = monatstageListe.findIndex((tag) => tag.classList.contains('tag-selected'));
	removeTagSelection();
	removeSchichtSelection();
	let folgeTag;
	// Durchwählen der Tage
	if (currentIndex < 0) {
		folgeTag = monatstageListe[0];
	} else if (currentIndex === 0 && richtung === -1) {
		folgeTag = monatstageListe[monatstageListe.length - 1];
	} else if (currentIndex === monatstageListe.length - 1 && richtung === 1) {
		folgeTag = monatstageListe[0];
	} else {
		folgeTag = monatstageListe[currentIndex + richtung];
	}
	const datumAktuell = folgeTag.getAttribute('aria-label');
	document.body.querySelector('#zeiten-datum-input').value = folgeTag.getAttribute('aria-label');
	folgeTag.classList.add('tag-selected');
	leseRegelarbeitszeiten(datumAktuell);
	const naechsteSchicht = folgeTag.querySelector('.zeiten-grid');
	if (naechsteSchicht !== null) {
		await selectSchicht(naechsteSchicht);
	} else {
		setTimeout(() => folgeTag.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }), 100);
	}
	document.querySelector('[aria-label="zeiten-Von"]').focus();
}

// Hebt die Auswahl eines bestimmten Tages auf
function removeTagSelection() {
	const prevSelections = document.body.querySelectorAll('.tag-selected');
	if (prevSelections.length >= 1) {
		prevSelections.forEach((tag) => { tag.classList.remove('tag-selected'); });
	}
}

// Hebt die Auswahl einer bestimmten Schicht auf
function removeSchichtSelection() {
	// bei Aufhebung der Auswahl resetten wir auch das Umsatzfeld
	document.body.querySelector('[aria-label="zeiten-Umsatz"]').value = 0;
	const prevSelections = document.body.querySelectorAll('.zeiten-item-selected');
	if (prevSelections.length >= 1) {
		prevSelections.forEach((schicht) => { schicht.classList.remove('zeiten-item-selected'); });
	}
	schichtSelected = null;
}

// Zeigt Änderungen einer Schicht in der Oberfläche an
async function editiereSchicht(inputObject) {
	await fuelleSchichtBalken(inputObject);
	await springeZeile();
}

// Füllt einen bestehenden Balken mit neuen Input
async function fuelleSchichtBalken(inputObject) {
	const balken = document.body.querySelector(`[id="${inputObject._id}"]`);
	const balkenArray = Array.from(balken.children);
	const egfz = await basedataService.holeEgfzBasedataProvider();
	balkenArray.forEach((feld) => {
		const label = feld.getAttribute('aria-label');
		if (label === 'Von' || label === 'Bis') {
			feld.innerText = dayjs(inputObject[label]).format('HH:mm');
			if (label === 'Bis' && !dayjs(inputObject[label]).isValid()) {
				feld.innerText = 'offen';
			}
		} else if (label === 'Egfz') {
			const egfzBDP = egfz.find((e) => e.EGFZID === inputObject[label]);
			feld.innerText = egfzBDP?.BezeichnungKurz ? egfzBDP?.BezeichnungKurz : '?';
			feld.title = egfzBDP?.Bezeichnung;
		} else if (label === 'Bezahltezeit') {
			// Wir haben überlänge falls die bezahlte Schichtzeit > 1 Tag oder > 10 Stunden ist.
			if (inputObject[label].split(':').length > 2 || Number(inputObject[label].split(':')[0]) >= 10) {
				feld.classList.add('ueberlaenge');
				feld.innerHTML = inputObject[label] + '<i class="bi bi-alarm-fill" title="bezahlte Zeit länger als 10 Stunden!"></i>';
			} else {
				feld.classList.remove('ueberlaenge');
				feld.innerHTML = inputObject[label];
			}
		} else if (label === 'Notiz') {
			if (inputObject[label]) {
				balken.title = `Notiz: ${inputObject[label]}`;
				feld.title = `Notiz: ${inputObject[label]}`;
				feld.style.display = 'block';
			} else {
				balken.title = '';
				feld.title = '';
				feld.style.display = 'none';
			}
		} else {
			feld.innerText = inputObject[label];
			feld.title = inputObject[label];
		}
	});
}

// Auswahl einer bestimmten Schicht und laden dessen aktueller Daten
async function selectSchicht(thisElement) {
	removeSchichtSelection();
	schichtSelected = thisElement.id;
	const result = await clickLeseSchicht(schichtSelected);
	if (_.isEmpty(result)) {
		return;
	}
	thisElement.parentElement.classList.add('tag-selected');
	thisElement.classList.add('zeiten-item-selected');
	const childrenArray = Array.from(thisElement.children);
	const egfz = await basedataService.holeEgfzBasedataProvider();
	// Fülle das Input Control mit den Schichtwerten
	childrenArray.forEach((element) => {
		const label = element.getAttribute('aria-label');
		if (!['Pausenzeit', 'Einsatzort', 'Posten', 'Qualifikation', 'Egfz', 'UrlaubID', 'Umsatz', 'JournalID', 'BezahlteStunden', 'GesamtStunden', 'PauseUnbezahltStunden', 'Notiz'].includes(label)) {
			document.body.querySelector(`[aria-label="zeiten-${label}"]`).value = element.innerText;
		}
		if (label === 'Egfz') {
			const egfzID = egfz.find((e) => e.BezeichnungKurz === element.innerText)?.EGFZID;
			document.body.querySelector(`[aria-label="zeiten-${label}"]`).value = egfzID ? egfzID : '';
		}
		if (label === 'Umsatz') {
			document.body.querySelector(`[aria-label="zeiten-${label}"]`).value = parseFloat(element.innerText).toFixed(2);
		}
		if (label === 'Notiz') {
			document.body.querySelector(`[aria-label="zeiten-${label}"]`).value = element.title.substring(7);
		}
	});
	document.querySelector('[aria-label="zeiten-Von"]').focus();
	thisElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
	unmarkiereSchichtenLoeschen();

	document.querySelectorAll('[aria-label="zeiten-Pausen"]').forEach((element) => element.remove());
	const pausenButton = document.getElementById('neue-pause-button');
	if (result.Pausen.length > 0) {
		result.Pausen.forEach((pause) => {
			pause.Von = dayjs(pause.Von).format('HH:mm');
			pause.Bis = dayjs(pause.Bis).format('HH:mm');
			pausenButton.insertAdjacentHTML('beforebegin', getNeuePauseHTML(pause));
		});
	} else {
		pausenButton.insertAdjacentHTML('beforebegin', getNeuePauseHTML());
	}
}

// Fügt eine neue Zeile bei der Pauseneingabe ein
function neuePause(thisElement) {
	thisElement.insertAdjacentHTML('beforebegin', getNeuePauseHTML());
}

// HTML Code für eine Pausenzeile
function getNeuePauseHTML(pause = { Von: '', Bis: '', Bezahlt: false }) {
	return `<div aria-label="zeiten-Pausen" class="pause-input">
			<div>
				<label> Von: </label>
				<input type="time" aria-label="pause-Von" class="zeiten-input zeiten-pause-input" onchange="myHandlers.berechneBezahlteZeit()" value="${pause.Von}"/>
				<label> Bis: </label>
				<input type="time" aria-label="pause-Bis" class="zeiten-input zeiten-pause-input" onchange="myHandlers.berechneBezahlteZeit()"  value="${pause.Bis}"/>
				<input title="bezahlte Pause?" type="checkbox" aria-label="pause-Bezahlt" onchange="myHandlers.berechneBezahlteZeit()" ${!pause.Bezahlt ? '' : 'checked'} />
				<label title="bezahlte Pause?">€</label>
				<input title="Raucherpause?" type="checkbox" aria-label="pause-Raucherpause" ${!pause.Raucherpause ? '' : 'checked'} />
				<label title="Raucherpause?">RP</label>
			</div>
			<div>
				<span onclick="this.parentElement.parentElement.remove()" title="Pause entfernen"><i class="bi bi-trash-fill" style="color: var(--custom-red);"></i></i></span>
			</div>
		</div>`;
}

// Berechnet die Differenz zwischen von und bis Zeiten an einem bestimmten Tag
function zeitDifferenz(datum, von, bis) {
	if (von === '' || bis === '') return 0;
	const datumVon = dayjs(`${datum.format('YYYY-MM-DD')}T${von}`);
	let datumBis = dayjs(`${datum.format('YYYY-MM-DD')}T${bis}`);

	// teste ob die "bis" Uhrzeit am nächsten Tag ist
	if (datumBis.diff(datumVon) < 0) {
		const nextDay = datum.add(1, 'day');
		datumBis = dayjs(`${nextDay.format('YYYY-MM-DD')}T${bis}`);
	}
	const duration = dayjs.duration(datumBis.diff(datumVon));

	return duration;
}

// Navigationsevents
async function inputNavigation(event) {
	const isEditable = window.myVars.zeitenEditable;
	// verhindere, dass Pfeiltasten was anderes machen als die Tag/Schicht Auswahl
	if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
		// Im Umsatzfeld wollen wir noch das default Verhalten behalten für den Input.
		if (event.target.tagName === 'INPUT' && (event.target.type === 'number' || event.target.classList.contains('zeiten-pause-input'))) {
			return;
		}
		event.preventDefault();
	}
	// Makiere mehrere Schichten zum Löschen
	if (isEditable && event.shiftKey && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
		markiereSchichtenLoeschen(event);
		return;
	}
	if (isEditable && event.ctrlKey && event.key.toUpperCase() === 'A') {
		markiereAlleSchichtenLoeschen();
		return;
	}
	// verhindere, dass zwei Schichten eingefügt werden, wenn man mit Enter auf OK drückt
	if (event.key === 'Enter' && event.target.type === 'button') {
		return;
	}
	if (isEditable && event.key === 'Enter') {
		enterElement(event);
		return;
	}
	if (isEditable && event.key === 'Delete' || event.key === 'Backspace') {
		// Im Umsatzfeld und Notizfeld brauchen wir diese Tasten ohne die Schicht zu löschen.
		if ((event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') && (event.target.type === 'number' || event.target.type === 'text' || event.target.type === 'textarea')) {
			return;
		}
		await deleteElements(event);
		return;
	}
	if (event.key === 'ArrowDown' || event.key === '+') {
		await wechsleTag(1);
		return;
	}
	if (event.key === 'ArrowUp' || event.key === '-') {
		await wechsleTag(-1);
		return;
	}
	if (event.key === 'ArrowLeft') {
		await wechsleSchichtHorizontal(-1);
		return;
	}
	if (event.key === 'ArrowRight') {
		await wechsleSchichtHorizontal(1);
	}
}

// wechselt den Tag und markiert diesen
async function wechsleTag(richtung) {
	await springeZeile(richtung);
}

// Wechselt zwischen Schichten am gleichen Tag
async function wechsleSchichtHorizontal(richtung) {
	const datum = document.querySelector('#zeiten-datum-input').value;
	if (datum === '') return;
	let aktuelleSchicht = document.querySelector('.zeiten-item-selected');
	if (aktuelleSchicht === null) {
		// Wähle Sie aus, falls es eine gibt...
		aktuelleSchicht = document.querySelector(`[aria-label='${datum}'].zeiten-grid`);
		if (aktuelleSchicht === null) return;
	} else {
		// Gehe zur nächsten Schicht...
		let schichten = document.querySelectorAll(`[aria-label='${datum}'].zeiten-grid`);
		schichten = Array.from(schichten);
		schichtenIndex = schichten.indexOf(aktuelleSchicht);
		if (richtung === -1) {
			// links
			schichtenIndex -= 1;
			if (schichtenIndex < 0) {
				removeSchichtSelection();
				return;
			}
		} else {
			// rechts
			schichtenIndex += 1;
			if (schichtenIndex >= schichten.length) {
				removeSchichtSelection();
				return;
			}
		}
		aktuelleSchicht = schichten[schichtenIndex];
	}
	await selectSchicht(aktuelleSchicht);
}

// Initialisiert den Löschvorgang von Schichten und ruft ein Dialog auf
async function deleteElements(event) {
	event.preventDefault();
	// Falls wir nur eine bestimmte Schicht löschen wollen
	if (schichtSelected !== null) {
		const schichtElement = document.getElementById(schichtSelected);
		if (checkDeleteUrlaub(schichtElement)) {
			await SystemDialogService.instance.displayAsync('zeiten-delete-urlaub-dialog');
			return;
		}
		const input = await SystemDialogService.instance.displayAsync('zeiten-delete-dialog');
		if (!input.success) {
			return;
		}
		await entferneSchicht();
		return;
	}
	// Falls wir mehrere Schichten löschen wollen
	const schichten = Array.from(document.querySelectorAll('.zeiten-item-loeschen'));
	if (schichten.length >= 1) {
		const enthaeltUrlaub = schichten.some((schicht) => checkDeleteUrlaub(schicht));
		if (enthaeltUrlaub) {
			await SystemDialogService.instance.displayAsync('zeiten-delete-urlaub-dialog');
			return;
		}
		const input = await SystemDialogService.instance.displayAsync('zeiten-delete-dialog');
		if (!input.success) {
			return;
		}
		await entferneSchicht();
	}
	toggleUeberlaengeSymbol(stammdatenService.aktuellerMitarbeiter._id);
}

// Ausführen des Löschvorgangs nach OK im Dialog
async function entferneSchicht() {
	if (schichtSelected !== null) {
		const schichtElement = document.getElementById(schichtSelected);
		await schichtService.zeitLoeschen(schichtSelected);
		const schichtIndex = zeitenMonat.get().findIndex((zeit) => zeit._id === schichtSelected);
		zeitenMonat.remove(schichtIndex);
		schichtElement.remove();
	}
	if (document.querySelectorAll('.zeiten-item-loeschen').length >= 1) {
		const zeitenItems = document.querySelectorAll('.zeiten-item-loeschen');
		zeitenItems.forEach(async (schicht) => {
			await schichtService.zeitLoeschen(schicht.id);
			const schichtIndex = zeitenMonat.get().findIndex((zeit) => zeit._id === schicht.id);
			zeitenMonat.remove(schichtIndex);
			schicht.remove();
		});
	}
	schichtSelected = null;
}

/**
 * Überprüft ob die Schicht eine Urlaubsschicht ist, falls ja wird true returned
 * @param {HTMLElement} schichtElement
 * @returns
 */
function checkDeleteUrlaub(schichtElement) {
	const urlaubID = schichtElement.querySelector('[aria-label="UrlaubID"]').innerText;
	const regex = /^[0-9a-fA-F]{24}$/;
	if (urlaubID && regex.test(urlaubID)) {
		return true;
	}
	return false;
}

// Berechnet die eigentliche bezahlte Zeit einer Schicht.
function berechneBezahlteZeit(event) {
	// Falls keine Schicht ausgewählt ist und wir von/bis Zeiten ändern, dann löschen wir die Pausen...
	if (event && !schichtSelected) {
		const pausenInputHtml = document.body.querySelectorAll('[aria-label="zeiten-Pausen"]');
		pausenInputHtml.forEach((pauseHtml) => pauseHtml.remove());
	}
	const zeitenInputBody = document.body.querySelector('#zeiten-input-body');
	let datum = zeitenInputBody.querySelector('#zeiten-datum-input').value;
	if (datum === '') datum = dayjs();
	else datum = dayjs(datum);
	const von = zeitenInputBody.querySelector('[aria-label="zeiten-Von"]').value;
	const bis = zeitenInputBody.querySelector('[aria-label="zeiten-Bis"]').value;
	if (von === '' || bis === '') return;
	let bezahlteZeit = zeitDifferenz(datum, von, bis);
	const pausenzeiten = berechnePausenZeit(datum);
	bezahlteZeit = bezahlteZeit.$ms - pausenzeiten.PauseUnbezahlt.$ms;
	zeitenInputBody.querySelector('[aria-label="zeiten-Bezahltezeit"]').value = dayjs
		.duration(bezahlteZeit)
		.format('HH:mm');
}

// Berechnet die Pausenzeiten einer Schicht
function berechnePausenZeit(datum) {
	const pausenInputHtml = document.body.querySelectorAll('[aria-label="zeiten-Pausen"]');
	let PauseUnbezahlt = 0;
	let PauseGesamt = 0;
	pausenInputHtml.forEach((pause) => {
		const von = pause.querySelector('[aria-label="pause-Von"]').value;
		const bis = pause.querySelector('[aria-label="pause-Bis"]').value;
		if (von === '' || bis === '') return;
		const pauseDiff = zeitDifferenz(datum, von, bis);
		if (!pause.querySelector('[aria-label="pause-Bezahlt"]').checked) {
			PauseUnbezahlt += pauseDiff.$ms;
		}
		PauseGesamt += pauseDiff.$ms;
	});
	PauseGesamt = dayjs.duration(PauseGesamt);
	PauseUnbezahlt = dayjs.duration(PauseUnbezahlt);

	return { PauseGesamt, PauseUnbezahlt };
}

// Liest die Pausen aus dem Input Feldern
function lesePausen(pausenInputHtml, datum) {
	const pausen = [];
	pausenInputHtml.forEach((pause) => {
		const pauseVon = pause.querySelector('[aria-label="pause-Von"]');
		const pauseBis = pause.querySelector('[aria-label="pause-Bis"]');
		// Falls eine Pause nicht angegeben ist, oder diese den gleichen Wert haben, überspringen wir die Pause.
		if ((pauseVon.value === '' || pauseBis.value === '') || (pauseVon.value === pauseBis.value)) {
			return;
		}
		const p = {};
		p.Von = pauseVon.value;
		p.Bis = pauseBis.value;
		p.Von = dayjs(`${datum.format('YYYY-MM-DD')}T${p.Von}`);
		p.Bis = dayjs(`${datum.format('YYYY-MM-DD')}T${p.Bis}`);
		// Wenn die Pausen Uhrzeit vor der Schicht Von Uhrzeit liegt, dann gehen wir vom nächsten Tag aus (da die Pause nach dem Schichtbeginn starten muss)
		// Das wäre zum Beispiel bei einem Schichtbeginn am Abend und einer Pause nach Mitternacht üblich
		if (p.Von.isBefore(datum) && p.Bis.isBefore(datum)) {
			const nextDay = p.Von.add(1, 'day');
			// wegen der Zeitumstellung können wir nicht einfach einen tag addieren...
			p.Von = dayjs(`${nextDay.format('YYYY-MM-DD')}T${pauseVon.value}`);
			p.Bis = dayjs(`${nextDay.format('YYYY-MM-DD')}T${pauseBis.value}`);
		}
		if (p.Bis.diff(p.Von) < 0) {
			const nextDay = p.Von.add(1, 'day');
			p.Bis = dayjs(`${nextDay.format('YYYY-MM-DD')}T${pauseBis.value}`);
		}
		p.Bezahlt = pause.querySelector('[aria-label="pause-Bezahlt"]').checked;
		p.Raucherpause = pause.querySelector('[aria-label="pause-Raucherpause"]').checked;
		p.Gesamt = dayjs.duration(p.Bis.diff(p.Von)).format('HH:mm');
		pausen.push(p);
	});
	return pausen;
}

// Springt zum nächsten Input Element nach Eingabe
function jumpNext(thisElement, nextElement, event) {
	event.stopPropagation();
	if (oldFocus === null) oldFocus = thisElement;
	if (oldFocus !== null && oldFocus !== thisElement) {
		keysPressedCount = 0;
		oldFocus = thisElement;
	}
	if (event.key === 'Backspace') {
		keysPressedCount = Math.max(keysPressedCount - 1, 0);
	}
	if (event.key === 'Tab') {
		keysPressedCount = 0;
	}
	// Nur zählen, wenn es eine Nummer war
	if (!Number.isNaN(parseInt(event.key, 10))) {
		keysPressedCount += 1;
	}

	if (keysPressedCount === 4) {
		document.querySelector(`[aria-label='${nextElement}']`).focus();
		keysPressedCount = 0;
	}
}

function resetKeysPressedCount() {
	keysPressedCount = 0;
}

// Verhindere, dass Enter sehr viele Schichten auf einmal erstellt bei gedrückthalten der Enter Taste
const updateThrottleEnter = throttle(() => {
	inputZeiten(false);
}, 100);

// Initialisierung der Schichterstellung durch Enter-Taste
function enterElement() {
	updateThrottleEnter();
}

// Führt die Funktion des callbacks in einem bestimmten Delay intervall aus, damit nicht gespammt wird
function throttle(cb, delay = 1000) {
	let shouldWait = false;
	let waitingArgs;
	const timeoutFunc = () => {
		if (waitingArgs == null) {
			shouldWait = false;
		} else {
			cb(...waitingArgs);
			waitingArgs = null;
			setTimeout(timeoutFunc, delay);
		}
	};

	return (...args) => {
		if (shouldWait) {
			waitingArgs = args;
			return;
		}

		cb(...args);
		shouldWait = true;

		setTimeout(timeoutFunc, delay);
	};
}

function resetDeleteIndices() {
	schichtenIndex = null;
	deleteStartIndex = null;
}

// Wir markieren die Schichten, die wir löschen wollen in rot
function markiereSchichtenLoeschen(event) {
	const schichten = Array.from(document.querySelectorAll('.zeiten-grid:not(#zeiten-grid-legende)'));

	// Falls es keine Schichten gibt, machen wir nix.
	if (schichten.length <= 0) {
		resetDeleteIndices();
		return;
	}

	// Falls eine Schicht selektiert ist, starten wir hier.
	if (schichtSelected !== null) {
		const tempSelection = document.getElementById(schichtSelected);
		tempSelection.classList.add('zeiten-item-loeschen');
		schichtenIndex = schichten.indexOf(tempSelection);
		deleteStartIndex = schichtenIndex;
		removeSchichtSelection();
		return;
	}
	// Falls wir noch keinen Ausgangspunkt haben, verwenden wir das erste Element
	if (event.key === 'ArrowDown' && schichtenIndex === null) {
		schichtenIndex = 0;
		deleteStartIndex = schichtenIndex;
		schichten[schichtenIndex].classList.add('zeiten-item-loeschen');
		removeSchichtSelection();
		return;
	}

	// Falls wir noch keinen Ausgangspunkt haben, verwenden wir das letzte Element
	if (event.key === 'ArrowUp' && schichtenIndex === null) {
		schichtenIndex = schichten.length - 1;
		deleteStartIndex = schichtenIndex;
		schichten[schichtenIndex].classList.add('zeiten-item-loeschen');
		removeSchichtSelection();
		return;
	}

	if (event.key === 'ArrowUp') {
		// ArrowUp:
		schichtenIndex = Math.max(schichtenIndex - 1, -1);
		// Falls wir out-of-bounds geraten würden, machen wir nix und reseten auf den ersten Index!
		if (schichtenIndex === -1) {
			schichtenIndex = 0;
			return;
		}
		// markiere wenn der schichtindex oberhalb (kleiner) des startindex ist.
		if (schichtenIndex < deleteStartIndex) {
			schichten[schichtenIndex].classList.add('zeiten-item-loeschen');
		} else if (schichtenIndex + 1 < schichten.length) schichten[schichtenIndex + 1].classList.remove('zeiten-item-loeschen');
	} else {
		// ArrowDown:
		schichtenIndex = Math.min(schichtenIndex + 1, schichten.length);
		// Falls wir out-of-bounds geraten würden, machen wir nix und resetten auf den letzten Index!
		if (schichtenIndex === schichten.length) {
			schichtenIndex = schichten.length - 1;
			return;
		}
		// markiere wenn der schichtindex oberhalb (kleiner) des startindex ist.
		if (schichtenIndex > deleteStartIndex) {
			schichten[schichtenIndex].classList.add('zeiten-item-loeschen');
		} else if (schichtenIndex - 1 >= 0) {
			schichten[schichtenIndex - 1].classList.remove('zeiten-item-loeschen');
		}
	}
}

// Aufhebung der Löschmarkierungen
function unmarkiereSchichtenLoeschen() {
	const markierteSchichten = document.querySelectorAll('.zeiten-item-loeschen');
	markierteSchichten.forEach((schicht) => {
		schicht.classList.remove('zeiten-item-loeschen');
		resetDeleteIndices();
	});
}

// Markierung aller Schichten zum Löschen
function markiereAlleSchichtenLoeschen() {
	const schichten = Array.from(document.querySelectorAll('.zeiten-grid:not(#zeiten-grid-legende)'));
	schichten.forEach((schicht) => {
		schicht.classList.add('zeiten-item-loeschen');
	});
}



// Lese Request einer bestimmten Schicht
async function clickLeseSchicht(schichtID) {
	const schicht = await schichtService.leseSchicht(schichtID);
	if (!_.isEmpty(schicht)) {
		await fuelleSchichtBalken(schicht);
		return schicht;
	}
	return false;
}

/**
 * Trägt mehrere Schichten ein mit EGFZ und Sollzeiten, z.B. für Urlaub
 * @param {numberString} egfzID bestimmt den EGFZ Typ, hier: 2: Urlaub, 3: Krank, 4: Schule, 23: Kurzarbeit
 */
async function inputEgfzTage(egfzID) {
	egfzID = parseInt(egfzID, 10);
	if (!egfzID) {
		return;
	}
	const mitarbeiterBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
	// Schichten, die SV Tage kürzen treten auch an nicht Regelarbeitstagen auf.
	const egfzArray = await basedataService.holeEgfzBasedataProvider();
	const kuerztSvTage = _.pluck(egfzArray.filter((egfz) => egfz.KuerztSVTage), 'EGFZID').includes(egfzID);

	let datumVon = document.querySelector('#input-von').value;
	let datumBis = document.querySelector('#input-bis').value;
	checkValidEgfzTageInput(datumVon, datumBis);
	// Datum in dayjs Date konvertieren
	datumVon = dayjs(datumVon);
	datumBis = dayjs(datumBis);
	// URLAUB wird separat erstellt
	if (egfzID === 2) {
		await inputEgfzTageUrlaub(datumVon, datumBis);
		return;
	}

	const tageAnzahl = datumBis.diff(datumVon, 'day');
	// Relevante Daten laden...
	const bisherigeSchichten = await schichtService.leseAlleSchichtenMitarbeiter(datumVon.startOf('day'), datumBis.endOf('day'), stammdatenService.aktuellerMitarbeiter._id);
	const sollstunden = mitarbeiterBeschaeftigung.SollstundenTag;
	const qualiSelect = document.body.querySelector('[aria-label="zeiten-QualifikationID"]');
	const einsatzortSelect = document.body.querySelector('[aria-label="zeiten-EinsatzortID"]');
	const postenSelect = document.body.querySelector('[aria-label="zeiten-PostenID"]');
	if (!qualiSelect.options[qualiSelect.selectedIndex]) {
		systemNachrichtService.zeigeKleineNachricht('Mitarbeiter besitzt noch keine Qualifikation!', 0);
		return;
	}
	const Qualifikation = qualiSelect.options[qualiSelect.selectedIndex].innerText;
	if (!postenSelect.options[postenSelect.selectedIndex]) {
		systemNachrichtService.zeigeKleineNachricht('Mitarbeiter ist noch keinem Posten zugeordnet!', 0);
		return;
	}
	const Posten = postenSelect.options[postenSelect.selectedIndex].innerText;
	const Einsatzort = einsatzortSelect.options[einsatzortSelect.selectedIndex].innerText;
	const QualifikationID = qualiSelect.value;
	const PostenID = postenSelect.value;
	const EinsatzortID = einsatzortSelect.value;
	const BetriebsstaetteID = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].BetriebsstaetteID;
	const regeltage = mitarbeiterBeschaeftigung.Regelarbeitstage;
	const hatRegelarbeitstage = regeltage.some((zeit) => zeit);
	const urlaubFeiertag = mitarbeiterBeschaeftigung.UrlaubAmFeiertag;
	for (let i = 0; i <= tageAnzahl; i += 1) {
		// Wir addieren jeweils die Tage auf ein temporäres Datum.
		const tmpDate = datumVon.add(i, 'day');
		// check ob schon eine Schicht an dem Tag vorliegt, falls nicht können wir eine Schicht erstellen.
		if (!bisherigeSchichten.some((schicht) => dayjs(schicht.Datum).isSame(tmpDate, 'day'))) {
			// Montag ist der Tag im Index 0 und Sonntag der letzte also Index 6.
			const wochentagIndex = tmpDate.day() === 0 ? 6 : tmpDate.day() - 1;
			let egfzNummmer = egfzID;
			const isFeiertag = feiertageBundesland.some((feiertag) => dayjs(feiertag.date).isSame(tmpDate));
			// Wir stellen Schichten nur ein, falls es ein Regelarbeitstag ist... (außer bei unbezahlten Tagen, die SV Tage kürzen)
			// Wenn der Mitarbeiter keine Regelarbeitszeiten hat, stellen wir Schichten per SolltageWoche ein, beginnend mit dem ersten Tag
			const tagDiff = mitarbeiterBeschaeftigung.SolltageWoche - (i % 7);
			if (kuerztSvTage || (hatRegelarbeitstage && regeltage[wochentagIndex]) || (!hatRegelarbeitstage && tagDiff > 0)) {
				// von und bis Datum müssen aus den Sollstunden ermittelt werden...
				const von = dayjs(`${tmpDate.format('DD.MM.YYYY')} 08:00`, 'DD.MM.YYYY HH:mm');
				const bis = von.add(sollstunden, 'hour').second(0);
				// Urlaub wird durch einen Feiertag ersetzt...
				if (!urlaubFeiertag && isFeiertag && egfzNummmer === 2) {
					egfzNummmer = 7;
				}
				// Schule wird durch einen Feiertag ersetzt...
				if (isFeiertag && egfzNummmer === 4) {
					egfzNummmer = 7;
				}
				// (un)bezahlt frei wird durch ein Feiertag ersetzt...
				if (!urlaubFeiertag && isFeiertag && egfzNummmer === 26) {
					egfzNummmer = 7;
				}
				if (!urlaubFeiertag && isFeiertag && egfzNummmer === 6) {
					egfzNummmer = 7;
				}
				// Für jeden Tag erstellen wir eine Schicht...
				const inputObject = {
					Datum: von.toDate(), // wir setzen Von Date und Datum gleich, da sonst Probleme bei Serverzeit auftreten könnten
					Von: von.toDate(),
					Bis: bis.toDate(),
					Egfz: egfzNummmer,
					MitarbeiterID: stammdatenService.aktuellerMitarbeiter._id,
					Bezahltezeit: dayjs.duration(bis.diff(von)).format('HH:mm'),
					Posten,
					PostenID,
					Qualifikation,
					QualifikationID,
					Einsatzort,
					EinsatzortID,
					BetriebsstaetteID,
					Gesamtzeit: '',
					Pausen: [],
					Pausenzeit: '00:00',
					Titel: basedataService.egfz.find((e) => e.EGFZID === egfzNummmer)?.BezeichnungKurz
				};
				// Beim Server speichern
				const result = await schichtService.speichereSchicht(inputObject, true);
				if (!result) {
					systemNachrichtService.zeigeKleineNachricht('Fehler beim Erstellen!', -1);
					return;
				}
				// Neuen Balken erstellen
				await erstelleSchichtElement(result);
				zeitenMonat.push(result);
			}
		}
	}
	zeitenMonat.callCallback();
	systemNachrichtService.zeigeKleineNachricht('EGFZ Tage erfolgreich eingetragen!', 1);
}

/**
 * Erstellt Urlaubsschichten inklusive Urlaubsantrag
 * @param {*} datumVon 
 * @param {*} datumBis 
 */
async function inputEgfzTageUrlaub(datumVon, datumBis) {
	const urlaubsKollisionIndex = checkUrlaubsantragKollisionen(stammdatenService.aktuellerMitarbeiter, datumVon, datumBis);
		if (urlaubsKollisionIndex >= 0) {
			await SystemDialogService.instance.displayAsync('zeiten-urlaub-kollision-dialog');
			return;
		}
		const result = await schichtService.erstelleUrlaubsschichten(stammdatenService.aktuellerMitarbeiter._id, datumVon, datumBis);
		if (result) {
			await zeitenScript();
		}
		return;
}

function checkValidEgfzTageInput(datumVon, datumBis) {
	if (datumVon === '' || datumBis === '') {
		const dialogText = 'Bitte von und bis Datum angeben!';
		systemNachrichtService.zeigeKleineNachricht(dialogText, -1);
		return;
	}
	
	// Datum in dayjs Date konvertieren
	datumVon = dayjs(datumVon);
	datumBis = dayjs(datumBis);

	if (datumBis.isBefore(datumVon)) {
		const dialogText = 'Das bis Datum muss nach dem von Datum liegen!';
		systemNachrichtService.zeigeKleineNachricht(dialogText, -1);
		return;
	}
	const maEintritt = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0].Eintrittsdatum;
	// Ohne Eintrittsdatum wird kein Schichteninput erlaubt...
	if (_.isEmpty(maEintritt)) {
		systemNachrichtService.zeigeKleineNachricht('Bitte erst das Eintrittsdatum der Beschäftigung festlegen!', 0);
		return;
	}
	// Falls der user eine Schicht vor dem Eintrittsdatum des Mitarbeiters eingeben will, wird dies geblockt.
	if (datumVon.isBefore(maEintritt)) {
		systemNachrichtService.zeigeKleineNachricht('Schichtdatum darf nicht vor Eintrittsdatum liegen!', -1);
		return;
	}
}

function berechneEgfzTageAnzahl() {
	let datumVon = document.querySelector('#input-von').value;
	let datumBis = document.querySelector('#input-bis').value;
	if (datumVon === '' || datumBis === '') {
		document.body.querySelector('[aria-label="egfz-gesamt"]').value = '';
		return;
	}

	datumVon = dayjs(datumVon);
	datumBis = dayjs(datumBis);
	if (datumBis.isBefore(datumVon)) {
		document.body.querySelector('[aria-label="egfz-gesamt"]').value = '';
		return;
	}
	const mitarbeiterBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
	const regeltage = mitarbeiterBeschaeftigung.Regelarbeitstage;
	const hatRegelarbeitstage = regeltage.some((zeit) => zeit);
	const tageAnzahl = datumBis.diff(datumVon, 'day');
	let tageCounter = 0;
	for (let i = 0; i <= tageAnzahl; i += 1) {
		const tmpDate = datumVon.add(i, 'day');
		// Montag ist der Tag im Index 0 und Sonntag der letzte also Index 6.
		const wochentagIndex = tmpDate.day() === 0 ? 6 : tmpDate.day() - 1;
		// Wir stellen zählen nur, falls es ein Regelarbeitstag ist...
		const tagDiff = mitarbeiterBeschaeftigung.SolltageWoche - (i % 7);
		if ((hatRegelarbeitstage && regeltage[wochentagIndex]) || (!hatRegelarbeitstage && tagDiff > 0)) {
			tageCounter += 1;
		}
	}
	document.body.querySelector('[aria-label="egfz-gesamt"]').value = tageCounter;
}

async function monatlicheGutzeitMA() {
	const zeitraum = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
	await schichtService.erstelleMonatlicheGutzeitMA(stammdatenService.aktuellerMitarbeiter._id, zeitraum);
	zeitenScript(true);
}

export default zeitenScript;
