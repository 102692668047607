import stammdatenService from '../services/stammdatenService.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereBetriebsregeln = speichereBetriebsregeln;


function ladeBetriebsregeln() {
    const unternehmen = stammdatenService.unternehmensobjekt;
    document.getElementById('u-workflowregeln').value = unternehmen?.Workflowregeln;
    document.getElementById('u-betriebsregeln').value = unternehmen?.Betriebsregeln;
    document.getElementById('u-mitarbeiterregeln').value = unternehmen?.Mitarbeiterregeln;
    document.getElementById('u-auswertungen').value = unternehmen?.Auswertungsregeln;
    document.getElementById('u-kontrolle').value = unternehmen?.Kontrollregeln;
}

async function speichereBetriebsregeln(thisElement) {
    const unternehmen = stammdatenService.unternehmensobjekt;
    const feld = thisElement.getAttribute('aria-label');
    unternehmen[feld] = thisElement.value;
    updateDebounceUnternehmen(unternehmen);
}

export {
    ladeBetriebsregeln,
};
