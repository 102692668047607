export const Egfz = {
    Arbeit: 1,
    Urlaub: 2,
    Krank: 3,
    Schule: 4,
    Seminar: 5,
    UnbezahltFrei: 6,
    Feiertag: 7,
    Geschworener: 8,
    Schoeffe: 9,
    Zeuge: 10,
    Umzug: 11,
    Untersuchung: 12,
    Eheschliessung: 13,
    EntbindungFrau: 14,
    BeerdigungFamilie: 15,
    Familienpflege: 16,
    Kur: 17,
    TodFamilie: 18,
    UnentschuldigtKrank: 19,
    Krank6Wochen: 20,
    GZAufbau: 21,
    GZAbbau: 22,
    Kurzarbeit: 23,
    Quarantaene: 24,
    BezahltFrei: 26,
    FreiNachVereinbarung: 27,
    KrankImFrei: 28,
    UnbezahltUrlaub: 29,
    RaucherPauseUnbezahlt: 30,
    PauseUnbezahlt: 31,
    PauseBezahlt: 32,
    RaucherPauseBezahlt: 33,
    KrankKurzarbeit: 34,
    Mutterschutz: 35,
    BeschVerbot: 36,
    Elternzeit: 37,
    KO: 40,
    u4K: 41,
    KindKrank: 42,
};

export const EgfzBezahlt = {
    Arbeit: 1,
    Urlaub: 2,
    Krank: 3,
    Schule: 4,
    Seminar: 5,
    Feiertag: 7,
    Geschworener: 8,
    Schoeffe: 9,
    Zeuge: 10,
    Umzug: 11,
    Untersuchung: 12,
    Eheschliessung: 13,
    EntbindungFrau: 14,
    BeerdigungFamilie: 15,
    Familienpflege: 16,
    Kur: 17,
    TodFamilie: 18,
    GZAbbau: 22,
    // Kurzarbeit: 23,
    // Quarantaene: 24,
    BezahltFrei: 26,
    PauseBezahlt: 32,
    RaucherPauseBezahlt: 33,
    // KrankKurzarbeit: 34,
    Mutterschutz: 35,
    BeschVerbot: 36,
    // KO: 40,
    // u4K: 41,
    // KindKrank: 42,
};
