import _ from 'underscore';
import dayjs from '../../../shared/services/dayjs.js';
import stammdatenService from './services/stammdatenService.js';
import systemNachrichtService from './services/systemNachrichtService.js';
import { CalenderService } from './services/calenderService.js';
import { ObservableArray, debounce, renderFilterListe } from './util.js';
import { Egfz } from '../../../shared/services/Egfz.js';

window.myHandlers = window.myHandlers || {};
window.myVars = window.myVars || {};
window.myHandlers.setFilterStatus = setFilterStatus;
window.myHandlers.toggleFilterParent = toggleFilterParent;

const pairs = ObservableArray();
pairs.setCallback((pairsNeu) => countPairStatus(pairsNeu));

const debouceUpdateTU = debounce(async (args) => {
    await updateZeitInTagesuebersicht(args[0], args[1]);
}, 1000);

const borderColors = {
    aktiv: 'var(--custom-green)',
    geplant: 'var(--secondary-color)',
    nichtAus: 'var(--custom-yellow)',
    fehlend: 'var(--custom-red)',
    beendet: 'black',
    frueh: 'var(--custom-yellow)',
    speat: 'var(--custom-yellow)'
};

const zeStatusText = {
    nichtAus: 'nicht ausgestempelt',
    frueh: 'zu früh',
    spaet: 'zu spät'
};

function countPairStatus(pairs) {
    let summeAktiv = 0;
    let summeGeplant = 0;
    let summeFehlt = 0;
    let summeBeendet = 0;
    let summeNichtAus = 0;
    let summeSpaet = 0;
    let summeFrueh = 0;
    let summeGesamt = 0;

    pairs.forEach((pair) => {
        // hidden elements are not counted
        if (pair.pairHTML.style.display == 'none') return;
        summeGesamt++;
        switch(pair.Status) {
            case 'aktiv':
                summeAktiv++;
                break;
            case 'geplant':
                summeGeplant++;
                break;
            case 'fehlend':
                summeFehlt++;
                break;
            case 'beendet':
                summeBeendet++;
                break;
            default:
                break;
        }
        if (!_.isEmpty(pair.Dienstplan) && !_.isEmpty(pair.Zeiterfassung)) {
            if (dayjs(pair.Zeiterfassung.SchichtVon).diff(pair.Dienstplan.SchichtVon, 'minutes') > 10) {
                summeSpaet++;
            }
            if (dayjs(pair.Dienstplan.SchichtVon).diff(pair.Zeiterfassung.SchichtVon, 'minutes') > 10) {
                summeFrueh++;
            }
            if ((pair.Zeiterfassung.SchichtBis == '' && dayjs().isAfter(pair.Dienstplan.SchichtBis)) || pair.Zeiterfassung.SchichtBis == '' && dayjs().isAfter(pair.Dienstplan.SchichtBis, 'hour')) {
                summeNichtAus++;
            }
        }
    });

    const listHeader = document.body.querySelector('.tu-list-header');
    listHeader.querySelector('#tu-count-aktiv').innerText = summeAktiv;
    listHeader.querySelector('#tu-count-geplant').innerText = summeGeplant;
    listHeader.querySelector('#tu-count-nicht-aus').innerText = summeNichtAus;
    listHeader.querySelector('#tu-count-fehlt').innerText = summeFehlt;
    listHeader.querySelector('#tu-count-beendet').innerText = summeBeendet;
    listHeader.querySelector('#tu-count-frueh').innerText = summeFrueh;
    listHeader.querySelector('#tu-count-spaet').innerText = summeSpaet;
    listHeader.querySelector('#tu-count-alle').innerText = summeGesamt;
};

async function ladeTagesuebersicht(datum = new Date()) {
    const calContainer = document.body.querySelector('.cal-container');
    const calService = new CalenderService(calContainer, datum, ladeSchichtPaare);
    await renderFilterListe(filterTagesuebersichtList);
}

function startRefreshTimeout(datumSelected) {
    // altes Interval löschen
    clearTimeout(window.myVars.intervalTU);
    window.myVars.intervalTU = setTimeout(() => {
        // falls wir nicht mehr auf der richten seite sind, löschen wir das Timeout
        if (window.myVars.websiteStatus != 'tagesuebersicht') {
            clearTimeout(window.myVars.intervalTU);
            return;
        }
        ladeSchichtPaare(datumSelected);
    }, 30 * 1000); // jede 0.5min updaten
}

/**
 * lädt die Schicht Paare und rendert sie.
 * @param {dayjs} datumSelected 
 */
async function ladeSchichtPaare(datumSelected) {
    // refresh Interval bei neuladen resetten.
    startRefreshTimeout(datumSelected);
    console.log('Lade Tagesübersicht für:', datumSelected.format('DD.MM.YYYY'));
    // lade Schichten vom Server
    const result = await fetchTagesschichten(datumSelected.format('YYYY-MM-DD'), stammdatenService.aktuelleBetriebsstaette);
    // Liste der Schichten
    const list = document.querySelector('.tu-list');
    // Reset der Liste bei Neuladen
    list.innerHTML = '';
    // Vorlage der Schicht Paare
    const templateTu = document.body.querySelector('[tu-item-template]');
    // Für jedes Paar rendern wir nun die Liste
    result.forEach((pair) => {
        // jedes Schichtpaar kann einen Zeiterfassung Status haben für früh, spät, nicht ausgestempelt
        pair.StatusZE = {};
        const newItem = templateTu.content.cloneNode(true).children[0];
        newItem.querySelector('[aria-label="item-name"]').innerText = pair.Name;
        newItem.querySelector('[aria-label="item-posten"]').innerText = pair.Posten;
        newItem.querySelector('[aria-label="item-quali"]').innerText = `(${pair.Qualifikation})`;
        newItem.querySelector('[aria-label="dienstplan-status"]').innerText = pair.Status;
        // Vorerst wird der Rahmen des List Elements nach dem allgemeinen Status (aktiv, geplant, fehlend, beendet) gefärbt
        newItem.style.borderColor = borderColors[pair.Status];
        // Wenn es eine Dienstplan Schicht gibt, dann Zeigen wir die wichtigsten Details an.
        if (!_.isEmpty(pair.Dienstplan)) {
            newItem.querySelector('[aria-label="dienstplan-von"]').value = dayjs(pair.Dienstplan.SchichtVon).format('HH:mm');
            newItem.querySelector('[aria-label="dienstplan-bis"]').value = dayjs(pair.Dienstplan.SchichtBis).format('HH:mm');
            newItem.querySelector('[aria-label="dienstplan-titel"]').innerText = pair.Dienstplan.Titel;
        }
        // Wenn es eine Zeiterfassung Schicht gibt, dann zeigen wir dessen Details an, ansonsten bleibt das Input gesperrt.
        const zeVonInput = newItem.querySelector('[aria-label="zeiterfassung-von"]');
        const zeBisInput = newItem.querySelector('[aria-label="zeiterfassung-bis"]');
        if (!_.isEmpty(pair.Zeiterfassung)) {
            zeVonInput.value = dayjs(pair.Zeiterfassung.SchichtVon).format('HH:mm');
            zeVonInput.addEventListener('input', async (event) => debouceUpdateTU(pair.Zeiterfassung._id, event));
            zeVonInput.disabled = false;
            zeBisInput.value = pair.Zeiterfassung.SchichtBis ? dayjs(pair.Zeiterfassung.SchichtBis).format('HH:mm') : '';
            zeBisInput.addEventListener('input', async (event) => debouceUpdateTU(pair.Zeiterfassung._id, event));
            zeBisInput.disabled = false;
            renderPauseZeiterfassung(pair, newItem);
            renderBezahlteZeiterfassung(pair, newItem);
        }
        renderStatusZeiterfassung(pair, newItem, zeVonInput, zeBisInput);
        list.append(newItem);
        // wir wollen später Zugriff auf das HTML Element haben, dazu speichern wir das HTML Element einfach mit.
        pair.pairHTML = newItem;
    });
    // Wir setzen das Ergebnis in unser Observable Array, damit die countStatus Funktion ausgeführt wird.
    pairs.set(result);
    // Wir filtern auch gleich (falls schon filter ausgewählt wurden)
    filterTagesuebersichtList();
}

/**
 * Server Anfrage für die Schichten an einem bestimmten Tagesdatum für einen Betrieb
 * @param {*} datum 
 * @param {*} betriebID 
 * @returns array<Schichtpaare>
 */
async function fetchTagesschichten(datum, betriebID) {
    try {
        const response = await fetch(`/neolohn/api/tagesuebersicht/schichten/${betriebID}?datum=${datum}`);
        if (!response.ok) {
            const message = await response.text();
            systemNachrichtService.zeigeKleineNachricht(message, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Schichten', -1);
    }
}

/**
 * Übernimmt Änderungen des Updates in die Oberfläche nach erfolgreichem update
 * @param {*} zeiterfassungID 
 * @param {*} event 
 */
async function updateZeitInTagesuebersicht(zeiterfassungID, event) {
    const pairIndex = pairs.get().findIndex((pair) => pair.Zeiterfassung?._id === zeiterfassungID);
    if (pairIndex >= 0) {
        const pair = pairs.get()[pairIndex];
        let zeiterfassung = pair.Zeiterfassung;
        const zeitParent = event.target.parentNode;
        const vonInput = zeitParent.querySelector('[aria-label="zeiterfassung-von"]');
        const vonStr = vonInput.value;
        // ohne Von Zeit, machen wir nix
        if (vonStr == '') return;
        const bisInput = zeitParent.querySelector('[aria-label="zeiterfassung-bis"]');
        const bisStr = bisInput.value;
        const abmeldung = zeiterfassung.SchichtBis == '' && bisStr != '';
        // Server Request ausführen
        const result = await updateTagesuebersichtSchicht(zeiterfassung._id, vonStr, bisStr); 
        if (result) {
            // Update ins array schreiben
            pair.Zeiterfassung = result;
            // Oberfläche aktualisieren...
            vonInput.value = dayjs(result.SchichtVon).format('HH:mm');
            bisInput.value = result.SchichtBis ? dayjs(result.SchichtBis).format('HH:mm') : '';
            if (abmeldung) {
                pair.Status = 'beendet';
                pair.pairHTML.style.borderColor = borderColors['beendet'];
                pair.pairHTML.querySelector('[aria-label="dienstplan-status"]').innerText = 'beendet';
            }
            renderPauseZeiterfassung(pair, pair.pairHTML);
            renderBezahlteZeiterfassung(pair, pair.parentHTML);
            renderStatusZeiterfassung(pair, pair.pairHTML, vonInput, bisInput);
            countPairStatus(pairs.get());
        }
    }
}

/**
 * Zeigt die Pausenzeit und die einzelnen Pausen beim Hovern über der Pausenzeit an
 * @param {*} pair Das Dienstplan / Zeiterfassung Paar
 * @param {*} htmlItem Das zugehörige HTML Element
 */
function renderPauseZeiterfassung(pair, htmlItem) {
    if (pair.Zeiterfassung.PauseZeitGesamt >= 0) {
        const pauseTime = dayjs.duration(Math.round(dayjs.duration(pair.Zeiterfassung.PauseZeitGesamt, 'hours').asMinutes()), 'minutes').format('HH:mm');
        const pauseHTML = htmlItem.querySelector('[aria-label="zeiterfassung-pause"]');
        pauseHTML.value = pauseTime;
        // Falls es pausen gibt, erstellen wir die Pausenliste mit einem Hover Effekt
        const pausenInfo = document.createElement('ul');
        pausenInfo.classList.add('tu-pausen-info');
        pair.Zeiterfassung.Zeitbloecke.forEach((block) => {
            if ([Egfz.PauseBezahlt, Egfz.PauseUnbezahlt, Egfz.RaucherPauseBezahlt, Egfz.RaucherPauseUnbezahlt].includes(block.EgfzID)) {
                const pause = document.createElement('li');
                pause.innerText += `${dayjs(block.BlockVon).tz('Europe/Berlin').format('HH:mm')} - ${dayjs(block.BlockBis).tz('Europe/Berlin').format('HH:mm')}`;
                pausenInfo.appendChild(pause);
            }
        });
        pauseHTML.parentNode.classList.add('tu-hover-pausen');
        pauseHTML.parentNode.appendChild(pausenInfo);
    }
}

/**
 * Zeigt die bezahlte Zeit der IST Schicht an.
 * @param {*} pair Das Dienstplan / Zeiterfassung Paar
 * @param {*} htmlItem Das zuegehörige HTML Element
 */
function renderBezahlteZeiterfassung(pair, htmlItem) {
    if (pair.Zeiterfassung.BezahlteZeitGesamt >= 0) {
        const bezahltTime = dayjs.duration(Math.round(dayjs.duration(pair.Zeiterfassung.BezahlteZeitGesamt, 'hours').asMinutes()), 'minutes').format('HH:mm');
        const bezahltHTML = htmlItem.querySelector('[aria-label="zeiterfassung-bezahlt"]');
        bezahltHTML.value = bezahltTime;
    }
}

/**
 * Beschriftet und färbt das HTML Element je nach Status
 * @param {*} pair Das Dienstplan / Zeiterfassung Paar
 * @param {*} htmlItem Das zuegehörige HTML Element
 * @param {*} vonInput Zeiterfassung Von Input
 * @param {*} bisInput Zeiterfassung Bis Input
 */
function renderStatusZeiterfassung(pair, htmlItem, vonInput, bisInput) {
    // Wenn es Dienstplan und Zeiterfassung Schichten gibt, dann können wir den Zeiterfassung Status ermitteln
    if (!_.isEmpty(pair.Dienstplan) && !_.isEmpty(pair.Zeiterfassung)) {
        const itemStatus = htmlItem.querySelector('[aria-label="zeiterfassung-status"]');
        // Reset der alten Farbkodierung
        itemStatus.innerText = '';
        vonInput.style.color = '';
        bisInput.style.borderColor = '';
        bisInput.style.background = '';
        // Bei mehr als +5min Abweichung zum Dienstplan wird das Element mit rot als zu spät markiert.
        if (dayjs(pair.Zeiterfassung.SchichtVon).diff(pair.Dienstplan.SchichtVon, 'minutes') > 5) {
            const diff = dayjs(pair.Zeiterfassung.SchichtVon).diff(pair.Dienstplan.SchichtVon, 'minutes');
            itemStatus.innerText = diff < 100 ? `${diff}min ${zeStatusText['spaet']}` : zeStatusText['spaet'];
            vonInput.style.color = 'var(--custom-red)';
            htmlItem.style.borderBottomColor = 'var(--custom-red)';
            htmlItem.style.borderRightColor = 'var(--custom-red)';
            pair.StatusZE['spaet'] = true;
        } else {
            pair.StatusZE['spaet'] = false;
        }
        // Bei mehr als -5min Abweichung zum Dienstplan wird das Element mit gelb als zu früh markiert.
        if (dayjs(pair.Dienstplan.SchichtVon).diff(pair.Zeiterfassung.SchichtVon, 'minutes') > 5) {
            const diff = dayjs(pair.Dienstplan.SchichtVon).diff(pair.Zeiterfassung.SchichtVon, 'minutes');
            itemStatus.innerText = diff < 100 ? `${diff}min ${zeStatusText['frueh']}` : zeStatusText['frueh'];
            vonInput.style.color = 'var(--custom-red)';
            htmlItem.style.borderBottomColor = 'var(--custom-yellow)';
            htmlItem.style.borderRightColor = 'var(--custom-yellow)';
            pair.StatusZE['frueh'] = true;
        } else {
            pair.StatusZE['frueh'] = false;
        }
        // Falls die Zeiterfassung noch ein offenes Ende nach der Dienstplan Bis Zeit hat, dann wird dieser auch mit gelb als nicht ausgestempelt markiert.
        if ((pair.Zeiterfassung.SchichtBis == '' && dayjs().isAfter(pair.Dienstplan.SchichtBis))
            || (pair.Zeiterfassung.SchichtBis == '' && dayjs().isAfter(pair.Dienstplan.SchichtBis, 'hour'))) {
            htmlItem.style.borderColor = borderColors['nichtAus'];
            pair.StatusZE['nichtAus'] = true;
            bisInput.style.borderColor = 'var(--custom-yellow)';
            bisInput.style.background = 'var(--custom-light-yellow)';
            itemStatus.innerText += itemStatus.innerText.length <= 0 ? zeStatusText['nichtAus'] : `\n${zeStatusText['nichtAus']}`;
        } else {
            pair.StatusZE['nichtAus'] = false;
        }
    }
}

/**
 * Sendet die Update Anfrage mit Von und Bis Zeit einer Zeiterfassungsschicht
 * @param {string} schichtID entsprechende Schicht 
 * @param {string} von HH:mm
 * @param {string} bis HH:mm
 * @returns neues Schichtobjekt
 */
async function updateTagesuebersichtSchicht(schichtID, von, bis) {
    try {
        const response = await fetch('/neolohn/api/tagesuebersicht/schichten', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                SchichtID: schichtID,
                SchichtVon: von,
                SchichtBis: bis,
            })
        });
        if (!response.ok) {
            const message = await response.text();
            systemNachrichtService.zeigeKleineNachricht(message, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
        const result = await response.json();
        systemNachrichtService.zeigeKleineNachricht('Änderung erfolgreich!', 1);
        return result;
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Änderung fehlgeschlagen!', -1);
        return false;
    }
}

function setFilterStatus(thisElement) {
    thisElement.parentNode.querySelectorAll('.tu-header-status').forEach((filter) => {
        filter.style.background = '';
        filter.classList.remove('tu-filter-status-active');
    });
    if (thisElement.id != '') {
        thisElement.style.background = thisElement.style.borderColor ? thisElement.style.borderColor : `linear-gradient(160deg, var(--secondary-color) 50%, ${thisElement.style.borderBottomColor} 50%, ${thisElement.style.borderBottomColor} 100%)`;
        thisElement.classList.add('tu-filter-status-active');
    }
    filterTagesuebersichtList();
}

function filterStatus() {
    const filter = document.body.querySelector('.tu-filter-status-active');
    // Filter reset
    if (!filter) {
        return;
    }
    pairs.get().forEach((pair) => {
        if (filter.id == 'frueh' && !pair.StatusZE['frueh']) {
            pair.pairHTML.style.display = 'none';
        }
        if (filter.id == 'spaet' && !pair.StatusZE['spaet']) {
            pair.pairHTML.style.display = 'none';
        }
        if (filter.id == 'nicht-aus' && !pair.StatusZE['nichtAus']) {
            pair.pairHTML.style.display = 'none';
        }
        if (pair.Status != filter.id && !['frueh', 'spaet', 'nicht-aus'].includes(filter.id)) {
            pair.pairHTML.style.display = 'none';
        }
    });
}

/**
 * Klappt den Bereich Filter auf oder zu
 * @param {HTMLElement} thisElement 
 * @param {*} event 
 */
function toggleFilterParent(thisElement, event) {
    event.stopPropagation();
    thisElement.parentNode.querySelectorAll('.tu-filter-child').forEach((child) => {
        child.classList.toggle('hidden');
    });
    const className = 'tu-filter-parent-open';
    thisElement.classList.toggle(className);
}

/**
 * Filtert die Schichtpaare nach Auswahl an Posten
 */
function filterPosten() {
    const aktivePosten = [];
    // sammle alle aktiven Posten
    document.querySelectorAll('.tu-filter-child').forEach((child) => {
        if (child.querySelector('.tu-filter-child-input').checked) {
            aktivePosten.push(child.id);
        }
    });
    pairs.get().forEach((pair) => {
        if (!aktivePosten.includes(pair.PostenID)) {
            pair.pairHTML.style.display = 'none';
        }
    });
    countPairStatus(pairs.get());
}

/**
 * Damit Filter kombinierbar sind, müssen wir immer beide anwenden.
 */
function filterTagesuebersichtList() {
    // reset
    pairs.get().forEach((pair) => pair.pairHTML.style.display = 'grid');
    // filter posten
    filterPosten();
    // filter status
    filterStatus();
}

export {
    ladeTagesuebersicht
};