import _ from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';

import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import { displayArray, resetDialogInput } from '../util.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.clickErstelleJournalVorwert = clickErstelleJournalVorwert;
window.myHandlers.confirmJournalVorwertDialog = confirmJournalVorwertDialog;
window.myHandlers.clickEntferneJournalVorwert = clickEntferneJournalVorwert;


async function ladeHistorie(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        const historie = await stammdatenService.leseJournalHistorieMitarbeiter(aktuellerMA._id);
        const parentHTML = document.getElementById('ma-jh-panel');
        displayArray(historie, 'ma-jh-', parentHTML);
    }
}

async function clickErstelleJournalVorwert() {
    document.getElementById('ma-jh-vorwert-zeitraum').value = window.myVars.aktuellesMonatsDatum.format('YYYY-MM');
    const input = await SystemDialogService.instance.displayAsync('ma-jh-vorwert-dialog');
    if (input.success) {
        const result = await stammdatenService.erstelleJournalVorwertMA(stammdatenService.aktuellerMitarbeiter._id, input.data.Zeitraum, input.data.EgfzSchnittwert, input.data.AGKostenSchnittwert);
        if (result) {
            await ladeHistorie(stammdatenService.aktuellerMitarbeiter);
        }
        resetDialogInput('ma-jh-vorwert-dialog');
    }
}

function confirmJournalVorwertDialog() {
    const dialogHtml = document.getElementById('ma-jh-vorwert-dialog');
    SystemDialogService.instance.confirm({
        Zeitraum: dayjs(dialogHtml.querySelector('#ma-jh-vorwert-zeitraum').value).format('YYYY-MM-DD'),
        EgfzSchnittwert: parseFloat(dialogHtml.querySelector('#ma-jh-vorwert-egfz').value),
        AGKostenSchnittwert: parseFloat(dialogHtml.querySelector('#ma-jh-vorwert-agkosten').value)
    });
}

async function clickEntferneJournalVorwert(mitarbeiterID, zeitraum) {
    const result = await stammdatenService.entferneJournalVorwertMA(mitarbeiterID, zeitraum);
    if (result) {
        await ladeHistorie(stammdatenService.aktuellerMitarbeiter);
    }
}

export {
    ladeHistorie
};
