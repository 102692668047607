import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import basedataService from '../services/basedataService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayArray, fuelleSelectOptionen, resetDialogInput } from '../util.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereLohnart = speichereLohnart;
window.myHandlers.neueLohnartgruppe = neueLohnartgruppe;
window.myHandlers.entferneLohnartgruppe = entferneLohnartgruppe;
window.myHandlers.speichereLohnartgruppe = speichereLohnartgruppe;
window.myHandlers.einfuegenSelectElement = einfuegenSelectElement;
window.myHandlers.confirmLohnartengruppeDialog = confirmLohnartengruppeDialog;
window.myHandlers.lohnartengruppeAuswahlSelect = lohnartengruppeAuswahlSelect;
window.myHandlers.verwendeLohnart = verwendeLohnart;
window.myHandlers.entferneLohnart = entferneLohnart;
window.myHandlers.neuerBezug = neuerBezug;
window.myHandlers.speichereBezug = speichereBezug;
window.myHandlers.entferneBezug = entferneBezug;
window.myHandlers.confirmNeuerBezugDialog = confirmNeuerBezugDialog;
window.myHandlers.editLohnartengruppe = editLohnartengruppe;
window.myHandlers.confirmLohnartengruppeEditDialog = confirmLohnartengruppeEditDialog;

async function ladeLohnarten() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const lohnartenAuswahl = await basedataService.holeLohnartenBasedataProvider();

	displayArray(unternehmen.Lohnarten, 'la-');
	displayArray(unternehmen.NettoBeAbzuege, 'nba-');
	displayArray(lohnartenAuswahl, 'bdp-');
	displayArray(unternehmen.Lohnartengruppen, 'lag-');
}


/**
 * Verwendet die ausgewählte Lohnart für das Unternehmen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function verwendeLohnart(thisElement) {
	const lohnartID = thisElement.id;
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnarten = await basedataService.holeLohnartenBasedataProvider();
	const lohnart = lohnarten.find((la) => la._id === lohnartID);
	if (!lohnart) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
	const isInUnternehmen = unternehmen.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (isInUnternehmen >= 0) {
		// das unternehmen besitzt diese Lohnart schon
        systemNachrichtService.zeigeKleineNachricht('Das Unternehmen besitzt diese Lohnart bereits!', 0);
		return;
	}
	unternehmen.Lohnarten.push(lohnart);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	// Zeige die neue Lohnart oben an...
	const unternehmenNeu = await stammdatenService.holeUnternehmensdaten();
	displayArray(unternehmenNeu.Lohnarten, 'la-');
}

/**
 * Soll die ausgewählte Lohnart aus dem Unternehmen löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function entferneLohnart(thisElement) {
	const prefix = 'la-';
	const lohnartID = thisElement.id;
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnartIndex = unternehmen.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (lohnartIndex < 0) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
	// Lohnart aus dem Unternehmen löschen
	unternehmen.Lohnarten.splice(lohnartIndex, 1);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	document.getElementById(`${prefix}${lohnartID}`).remove();
}

/**
 * Soll die ausgewählte Lohnart aus dem Unternehmen löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function entferneBezug(thisElement) {
	const prefix = 'nba-';
	const bezugID = thisElement.id;
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnartIndex = unternehmen.NettoBeAbzuege.findIndex((nba) => nba._id === bezugID);
	if (lohnartIndex < 0) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
	// Lohnart aus dem Unternehmen löschen
	unternehmen.NettoBeAbzuege.splice(lohnartIndex, 1);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	document.getElementById(`${prefix}${bezugID}`).remove();
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereLohnart(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.closest('.la-zeile');
	// wir brauchen nur die ID, also schließen wir den prefix (la-) aus.
	const zeileID = zeile.id.split('-')[1];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const lohnart = unternehmen.Lohnarten.find((la) => la._id === zeileID);
	if (!lohnart) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	collectValues(lohnart, 'la-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereBezug(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.closest('.nba-zeile');
	// wir brauchen nur die ID, also schließen wir den prefix (nba-) aus.
	const zeileID = zeile.id.split('-')[1];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const bezug = unternehmen.NettoBeAbzuege.find((nba) => nba._id === zeileID);
	if (!bezug) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	if (thisElement.getAttribute('aria-label') == 'nba-ExportOhneMinus' && thisElement.checked) {
		bezug.InExport = true;
		zeile.querySelector('[aria-label="nba-InExport"]').checked = true;
	}
	collectValues(bezug, 'nba-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

function confirmNeuerBezugDialog() {
	const bezugName = document.getElementById('neu-bezug-name').value;
	SystemDialogService.instance.confirm(bezugName);
}

// Click handler von neue Netto Be/Abzüge anlegen
async function neuerBezug() {
	const bezugBez = await SystemDialogService.instance.displayAsync('neu-bezug-dialog');

	// vermeide leere Einträge
	if (!bezugBez.success) {
		return;
	}

	if (bezugBez.data === '') {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung Angebeben!', 0);
		return;
	}
	// Wir schicken den neuen Bezug an den Server
	const result = await stammdatenService.erstelleNettoBezug(bezugBez.data);
	if (!result) {
		return;
	}
	resetDialogInput('neu-bezug-dialog');
	// Aktualisierung der Oberfläche
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	displayArray(unternehmen.NettoBeAbzuege, 'nba-');

}


/**
 * Öffnet den Dialog für eine neue Lohnartengruppe
 */
async function neueLohnartgruppe() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const selectLA = document.getElementById('neu-lag-la-select');
	const selectNBA = document.getElementById('neu-lag-nba-select');
	const auswahlLA = document.getElementById('neu-lag-la-auswahl');
	const auswahlNBA = document.getElementById('neu-lag-nba-auswahl');
	// auswahl zurücksetzen
	auswahlLA.innerHTML = '';
	auswahlNBA.innerHTML = '';
	fuelleSelectOptionen(selectLA, unternehmen.Lohnarten, '_id', ['Lohnartennummer', 'Bezeichnung'], true, 'Lohnart auswählen', '', ' |', false);
	fuelleSelectOptionen(selectNBA, unternehmen.NettoBeAbzuege, '_id', ['Lohnartennummer', 'Bezeichnung'], true, 'Nettobezug auswählen', '', ' |', false);
	const result = await SystemDialogService.instance.displayAsync('neu-lag-dialog');
	if (result.success) {
		// todo handle dialog confirm...
		console.log(result.data);
		await stammdatenService.erstelleLohnartengruppe(result.data);
		// Oberfläche aktualisieren
		displayArray(stammdatenService.unternehmensobjekt.Lohnartengruppen, 'lag-');
	}
}

/**
 * Absenden des Erstelle Lohnartengruppe Dialog Inputs.
 */
function confirmLohnartengruppeDialog() {
	const lohnarten = [];
	const nettobezuege = [];
	const auswahlLA = document.getElementById('neu-lag-la-auswahl');
	const auswahlNBA = document.getElementById('neu-lag-nba-auswahl');
	// Auswahl an Lohnarten speichern
	[...auswahlLA.options].forEach((option) => {
		const lohnart = {
			_id: option.value,
			Lohnartennummer: parseInt(option.text.split(' |')[0]),
			Bezeichnung: option.text.split('|')[1].trim()
		};
		lohnarten.push(lohnart);
	});
	// Auswahl an NettoBeAbzuegen speichern
	[...auswahlNBA.options].forEach((option) => {
		const nba = {
			_id: option.value,
			Lohnartennummer: parseInt(option.text.split(' |')[0]),
			Bezeichnung: option.text.split('|')[1].trim()
		};
		nettobezuege.push(nba);
	});
	SystemDialogService.instance.confirm({
		Lohnarten: lohnarten,
		NettoBeAbzuege: nettobezuege,
		Name: document.getElementById('neu-lag-name').value
	});
}

/**
 * Nimm die Auswahl aus einem einfachen Dropdown Select und
 * fügt diese Auswahl in das mulitple value select.
 * @param {HTMLElement} thisElement Select der Auswahl aus dem Unternehmen
 * @param {String} prefix 'lag-la-' für Lohnarten oder 'lag-nba-' für NettoBeAbzuege
 * @returns 
 */
function einfuegenSelectElement(thisElement, prefix) {
	const value = thisElement.value;
	if (value == '') return;
	const text = thisElement.options[thisElement.selectedIndex].text;
	const auswahl = document.getElementById(`${prefix}auswahl`);
	// Verhindern von doppelten Einträgen
	if ([...auswahl.options].find((item) => item.value == value)) return;
	const option = document.createElement('option');
	option.value = value;
	option.innerText = text;
	auswahl.add(option);
}

/**
 * Aktualisiert den Lohnartgruppennamen
 * @param {HTMLElement} thisElement Inputfeld für Gruppenbezeichnung
 * @returns 
 */
async function speichereLohnartgruppe(thisElement) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const lagId = thisElement.closest('.lag-zeile').id.split('-')[1];
	const lohnartengruppe = unternehmen.Lohnartengruppen.find((lag) => lag._id === lagId);
	// Keinen Empty value / string speichern
	if (!thisElement.value) return;
	// namen input speichern
	lohnartengruppe.Name = thisElement.value;
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Event Handler für das Editieren der Lohnarten bzw NettoBeAzuege Auswahl einer Lohnartengruppe
 * @param {HTMLElement} thisElement bearbeiten button
 * @param {String} prefix 'lag-la-' für Lohnarten oder 'lag-nba-' für NettoBeAbzuege
 * @returns 
 */
async function editLohnartengruppe(thisElement, prefix) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const lagId = thisElement.closest('.lag-zeile').id.split('-')[1];
	const lohnartengruppe = unternehmen.Lohnartengruppen.find((lag) => lag._id === lagId);
	if (!lohnartengruppe) {
		return systemNachrichtService.zeigeKleineNachricht('Lohnartengruppe nicht gefunden!', -1);
	}
	const selectUnternehmen = document.getElementById('edit-lag-select'); // select links
	const selectAuswahl = document.getElementById('edit-lag-auswahl'); // select rechts
	fuelleSelectOptionen(selectUnternehmen, prefix == 'lag-la-' ? unternehmen.Lohnarten : unternehmen.NettoBeAbzuege, '_id', ['Lohnartennummer', 'Bezeichnung'], false, '', '', ' |', false);
	fuelleSelectOptionen(selectAuswahl, prefix == 'lag-la-' ? lohnartengruppe.Lohnarten : lohnartengruppe.NettoBeAbzuege, '_id', ['Lohnartennummer', 'Bezeichnung'], false, '', '', ' |', false);
	const result = await SystemDialogService.instance.displayAsync('edit-lag-dialog');
	if (result.success) {
		// todo handle dialog confirm...
		console.log(result.data);
		if (prefix == 'lag-la-') {
			lohnartengruppe.Lohnarten = result.data.Lohnarten;
		}
		if (prefix == 'lag-nba-') {
			lohnartengruppe.NettoBeAbzuege = result.data.Lohnarten;
		}
		// Unternehmensobjekt updaten
		await stammdatenService.updateUnternehmensdaten(unternehmen);
		// Oberfläche aktualisieren
		displayArray(stammdatenService.unternehmensobjekt.Lohnartengruppen, 'lag-');
	}
}

/**
 * Lohnartengruppe Bearbeiten Dialog Input absenden.
 */
function confirmLohnartengruppeEditDialog() {
	const lohnarten = [];
	const auswahlLA = document.getElementById('edit-lag-auswahl');
	[...auswahlLA.options].forEach((option) => {
		const lohnart = {
			_id: option.value,
			Lohnartennummer: parseInt(option.text.split(' |')[0]),
			Bezeichnung: option.text.split('|')[1].trim()
		};
		lohnarten.push(lohnart);
	});
	SystemDialogService.instance.confirm({
		Lohnarten: lohnarten
	});
}

/**
 * Event Handler für das hinzufügen oder entfernen der Auswahl von
 * Lohnarten bzw. NettoBeAbzuegen in einer Lohnartengruppe.
 * @param {boolean} add true => hinzufügen, false => entfernen
 */
function lohnartengruppeAuswahlSelect(add) {
	const auswahlLinks = document.getElementById('edit-lag-select');
	const auswahlRechts = document.getElementById('edit-lag-auswahl');
	const arrayRechts = [...auswahlRechts.options];
	// falls wir von links nach rechts hinzufügen, kopieren wir die options ins rechte select
	if (add) {
		const selectionLinks = [...auswahlLinks.selectedOptions];
		selectionLinks.forEach((auswahl) => {
			// falls es die auswahl noch nicht rechts gibt, fügen wir sie ein
			if (!arrayRechts.find((option) => auswahl.value == option.value)) {
				auswahlRechts.add(auswahl);
			}
		});
	} else {
		// falls wir entfernen, müssen die optionen entsprechend aus der Liste entfernt werden
		const selectionRechts = [...auswahlRechts.selectedOptions];
		selectionRechts.forEach((auswahl) => {
			auswahl.remove();
		});
	}
}

/**
 * Event Handler für das Entfernen einer Lohnartengruppe
 * @param {HTMLElement} thisElement entfernen button
 */
async function entferneLohnartgruppe(thisElement) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const lagId = thisElement.closest('.lag-zeile').id.split('-')[1];
	const lohnartengruppeIndex = unternehmen.Lohnartengruppen.findIndex((lag) => lag._id === lagId);
	// Lohnartengruppe aus dem Unternehmen löschen
	unternehmen.Lohnartengruppen.splice(lohnartengruppeIndex, 1);
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	document.getElementById(thisElement.closest('.lag-zeile').id).remove();
}

export {
	ladeLohnarten
};
