import _ from 'underscore';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayArray, erstelleMitarbeiterListe, fuelleSelectOptionen } from '../util.js';
import { debounceSpeichereMA } from './ma_daten.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereMABezug = speichereMABezug;
window.myHandlers.speichereMALohnart = speichereMALohnart;
window.myHandlers.verwendeMABezug = verwendeMABezug;
window.myHandlers.verwendeMALohnart = verwendeMALohnart;
window.myHandlers.entferneMABezug = entferneMABezug;
window.myHandlers.entferneMALohnart = entferneMALohnart;
window.myHandlers.verwendeLohnartengruppeMA = verwendeLohnartengruppeMA;
window.myHandlers.confirmAddLohnartengruppeMA = confirmAddLohnartengruppeMA;
/**
 * Lädt die Lohnarten und Bezuüge des Mitarbeiters und zeigt diese an.
 * Die Auswahl der Lohnarten und Bezüge aus dem Unternehmen werden ebenfalls geladen.
 * @param {object} aktuellerMA
 * @param {object} unternehmen
 */
function ladeMALohnarten(aktuellerMA, unternehmen) {
    if (!_.isEmpty(aktuellerMA)) {
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const lohnartenU = document.getElementById('u-la-panel');
        displayArray(unternehmen.Lohnarten, 'u-la-', lohnartenU);
        const lohnartenMA = document.getElementById('ma-la-panel');
        displayArray(aktuelleBeschaeftigung.Lohnarten, 'ma-la-', lohnartenMA);
        const bezugU = document.getElementById('u-nba-panel');
        displayArray(unternehmen.NettoBeAbzuege, 'u-nba-', bezugU);
        const bezugMA = document.getElementById('ma-nba-panel');
        displayArray(aktuelleBeschaeftigung.NettoBeAbzuege, 'ma-nba-', bezugMA);
    }
}

/**
 * Verwendet die ausgewählte Lohnart für den Mitarbeiter
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
 async function verwendeMALohnart(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const lohnartID = thisElement.id;
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const lohnartU = unternehmen.Lohnarten.find((la) => la._id === lohnartID);
	if (!lohnartU) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
    const isInMA = aktuelleBeschaeftigung.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (isInMA >= 0) {
		// der Mitarbeiter besitzt diese Lohnart schon
        systemNachrichtService.zeigeKleineNachricht('Der Mitarbeiter besitzt diese Lohnart bereits!', 0);
		return;
	}
    const lohnartMA = erstelleMALohnart(lohnartU);
    aktuelleBeschaeftigung.Lohnarten.push(lohnartMA);
    aktuelleBeschaeftigung.Lohnarten.sort((a, b) => a.Lohnartennummer - b.Lohnartennummer);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	// Zeige die neue Lohnart oben an...
	ladeMALohnarten(aktuellerMA, unternehmen);
    await erstelleMitarbeiterListe();
}

function erstelleMALohnart(lohnartUnternehmen) {
    const lohnartMA = _.omit(lohnartUnternehmen, ['Standardbetrag']);
    lohnartMA.Betrag = 0;
    lohnartMA.Menge = 1;
    lohnartMA.Faktor = lohnartUnternehmen.Standardbetrag;
    lohnartMA.Verwenden = true;
    return lohnartMA;
}

/**
 * Soll die ausgewählte Lohnart aus dem Mitarbeiter löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Lohnart ID)
 */
async function entferneMALohnart(thisElement) {
	const prefix = 'ma-la-';
	const lohnartID = thisElement.id;
	const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const lohnartIndex = aktuelleBeschaeftigung.Lohnarten.findIndex((la) => la._id === lohnartID);
	if (lohnartIndex < 0) {
		// Lohnart konnte nicht gefunden werden
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	// Lohnart aus dem Mitarbeiter löschen
	aktuelleBeschaeftigung.Lohnarten.splice(lohnartIndex, 1);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	document.getElementById(`${prefix}${lohnartID}`).remove();
    await erstelleMitarbeiterListe();
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
 async function speichereMALohnart(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (ma-la-) aus.
	const zeileID = zeile.id.split('-')[2];
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    const lohnart = aktuelleBeschaeftigung.Lohnarten.find((la) => la._id === zeileID);
	if (!lohnart) {
		// Lohnart ist nicht im Mitarbeiter findbar...
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	collectValues(lohnart, 'ma-la-', zeile);
    if (thisElement.getAttribute('aria-label') === 'ma-la-Einmalig') {
        if (thisElement.checked) {
            lohnart.Regelmaessig = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
        } else {
            lohnart.Regelmaessig = true;
        }
    }
    // Einmalig Datum überschreiben, falls Verwenden im anderen Monat gedrückt wurde.
    if (thisElement.getAttribute('aria-label') === 'ma-la-Verwenden' && thisElement.checked && lohnart.Regelmaessig !== true) {
        if (zeile.querySelector('[aria-label="ma-la-Einmalig"]').checked) {
            lohnart.Regelmaessig = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
        } else {
            lohnart.Regelmaessig = true;
        }
    }
	window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

/**
 * Verwendet den ausgewählten NettoBe/Abzug für das Unternehmen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die NettoBe/Abzug ID)
 */
 async function verwendeMABezug(thisElement) {
    const prefix = 'ma-nba-';
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const bezugID = thisElement.id;
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const bezugU = unternehmen.NettoBeAbzuege.find((nba) => nba._id === bezugID);
	if (!bezugU) {
		// Lohnart konnte nicht gefunden werden
		return;
	}
    const isInMA = aktuelleBeschaeftigung.NettoBeAbzuege.findIndex((nba) => nba._id === bezugID);
	if (isInMA >= 0) {
		// das unternehmen besitzt diese Lohnart schon
        systemNachrichtService.zeigeKleineNachricht('Der Mitarbeiter besitzt diesen Nettobezug bereits!', 0);
		return;
	}
    const bezugMA = bezugU;
    // Wir übernehmen den Standardbetrag und Verwenden den Bezug initial
    bezugMA.Betrag = bezugU.Standardbetrag;
    bezugMA.Verwenden = true;
    bezugMA.Regelmaessig = true;
    aktuelleBeschaeftigung.NettoBeAbzuege.push(bezugMA);
    aktuelleBeschaeftigung.NettoBeAbzuege.sort((a, b) => a.Lohnartennummer - b.Lohnartennummer);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	// Zeige die neue Lohnart oben an...
    displayArray(aktuelleBeschaeftigung.NettoBeAbzuege, prefix);
    await erstelleMitarbeiterListe();
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
 async function speichereMABezug(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen nur die ID, also schließen wir den prefix (nba-) aus.
	const zeileID = zeile.id.split('-')[2];
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const bezug = aktuelleBeschaeftigung.NettoBeAbzuege.find((nba) => nba._id === zeileID);
	if (!bezug) {
		// Bezug ist nicht im Mitarbeiter findbar...
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	collectValues(bezug, 'ma-nba-', zeile);
    if (thisElement.getAttribute('aria-label') === 'ma-nba-Einmalig') {
        if (thisElement.checked) {
            bezug.Regelmaessig = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
        } else {
            bezug.Regelmaessig = true;
        }
    }
    // Einmalig Datum überschreiben, falls Verwenden im anderen Monat gedrückt wurde.
    if (thisElement.getAttribute('aria-label') === 'ma-nba-Verwenden' && thisElement.checked && bezug.Regelmaessig !== true) {
        if (zeile.querySelector('[aria-label="ma-nba-Einmalig"]').checked) {
            bezug.Regelmaessig = window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD');
        } else {
            bezug.Regelmaessig = true;
        }
    }
	window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

/**
 * Soll den ausgewählten Bezug aus dem Mitarbeiter löschen
 * @param {HTMLElement} thisElement Button der geklickt wurde (enthält die Bezug ID)
 */
 async function entferneMABezug(thisElement) {
	const prefix = 'ma-nba-';
	const bezugID = thisElement.id;
	const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const bezugIndex = aktuelleBeschaeftigung.NettoBeAbzuege.findIndex((nba) => nba._id === bezugID);
	if (bezugIndex < 0) {
		// Lohnart konnte nicht gefunden werden
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
		return;
	}
	// Lohnart aus dem Mitarbeiter löschen
	aktuelleBeschaeftigung.NettoBeAbzuege.splice(bezugIndex, 1);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
	document.getElementById(`${prefix}${bezugID}`).remove();
    await erstelleMitarbeiterListe();
}

/**
 * Auswahl der Lohnartengruppe des Mitarbeiters
 */
async function verwendeLohnartengruppeMA() {
    const unternehmen = await stammdatenService.holeUnternehmensdaten();
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const selectLA = document.getElementById('ma-lag-select');
	fuelleSelectOptionen(selectLA, unternehmen.Lohnartengruppen, '_id', 'Name', true, 'Lohnartengruppe auswählen', '');
    const result = await SystemDialogService.instance.displayAsync('ma-lag-auswahl-dialog');
    if (result.success) {
        const lohnartengruppe = result.data;
        if (!lohnartengruppe) return systemNachrichtService.zeigeKleineNachricht('Lohnartengruppe nicht gefunden', -1);
        lohnartengruppe.Lohnarten.forEach((lohnart) => {
            const lohnartU = unternehmen.Lohnarten.find((la) => la._id == lohnart._id);
            const lohnartMA = aktuellerMA.Beschaeftigung[0].Lohnarten.find((la) => la._id == lohnart._id);
            // Falls wir die Lohnart im Unternehmen finden und der Mitarbeiter sie noch nicht besitzt, fügen wir sie ein.
            if (lohnartU && !lohnartMA) {
                aktuellerMA.Beschaeftigung[0].Lohnarten.push(erstelleMALohnart(lohnartU));
            }
        });
        aktuellerMA.Beschaeftigung[0].Lohnarten.sort((a, b) => a.Lohnartennummer - b.Lohnartennummer);
        lohnartengruppe.NettoBeAbzuege.forEach((bezug) => {
            const bezugU = unternehmen.NettoBeAbzuege.find((la) => la._id == bezug._id);
            let bezugMA = aktuellerMA.Beschaeftigung[0].NettoBeAbzuege.find((la) => la._id == bezug._id);
            // Falls wir den Bezug im Unternehmen finden und der Mitarbeiter ihn noch nicht besitzt, fügen wir ihn ein.
            if (bezugU && !bezugMA) {
                // Wir übernehmen den Standardbetrag und Verwenden den Bezug initial
                bezugMA = JSON.parse(JSON.stringify(bezugU));
                bezugMA.Betrag = bezugU.Standardbetrag;
                bezugMA.Verwenden = true;
                bezugMA.Regelmaessig = true;
                aktuellerMA.Beschaeftigung[0].NettoBeAbzuege.push(bezugMA);
            }
        });
        aktuellerMA.Beschaeftigung[0].NettoBeAbzuege.sort((a, b) => a.Lohnartennummer - b.Lohnartennummer);
        await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        // Zeige die neuen Lohnarten / Bezuege oben an...
        ladeMALohnarten(stammdatenService.aktuellerMitarbeiter, unternehmen);
    }
}

function confirmAddLohnartengruppeMA() {
    const lagSelct = document.getElementById('ma-lag-select');
    const lagId = lagSelct.value;
    const lohnartengruppe = stammdatenService.unternehmensobjekt.Lohnartengruppen.find((lag) => lag._id == lagId);
    SystemDialogService.instance.confirm(lohnartengruppe);
}

export {
    ladeMALohnarten
};
