import _ from 'underscore';

import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayValues } from '../util.js';
import { debounceSpeichereMA, validateZuschlaege } from './ma_daten.js';


window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereZuschlaege = speichereZuschlaege;
window.myHandlers.setzeZuschlaegeStandards = setzeZuschlaegeStandards;
window.myHandlers.setzeZuschlaegeAufNull = setzeZuschlaegeAufNull;

// Onclick Handler für das Speichern von Mitarbeiter Zuschlägen
async function speichereZuschlaege() {
    // TODO: Wähle die entsprechende Beschäftigung
    const bIndex = 0;
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (_.isEmpty(aktuellerMA)) {
        systemNachrichtService.zeigeKleineNachricht('Kein Mitarbeiter ausgewählt!', 0);
        return;
    }
    const htmlDiv = document.getElementById('ma-Zuschlaege');
    collectValues(aktuellerMA.Beschaeftigung[bIndex].Zuschlaege, 'ma-', htmlDiv);
    // Falls N6 in Höhe von N1 true ist, dann wollen wir N6Prozent in der gleichen Höhe wie N1Prozent haben, sonst 0
    aktuellerMA.Beschaeftigung[bIndex].Zuschlaege.N6Prozent = aktuellerMA.Beschaeftigung[bIndex].Zuschlaege.N6Prozent ? aktuellerMA.Beschaeftigung[bIndex].Zuschlaege.N1Prozent : 0;
    window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

async function setzeZuschlaegeStandards() {
    document.body.querySelectorAll('.sfn-input-prozent').forEach((input) => input.value = input.max);
    await speichereZuschlaege();
}

async function setzeZuschlaegeAufNull() {
    document.body.querySelectorAll('.sfn-input-prozent').forEach((input) => input.value = input.min);
    await speichereZuschlaege();
}

/**
 * Zeigt die eingetragenen Zuschläge des Mitarbeiters an
 * @param {object} aktuellerMA aktuell ausgewählter Mitarbeiter
 */
async function ladeZuschlaege(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        displayValues(aktuellerMA.Beschaeftigung[0].Zuschlaege, '', document.getElementById('ma-Zuschlaege'), 'ma-');
        validateZuschlaege(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

export {
    ladeZuschlaege
};

