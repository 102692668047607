export const Roles = {
	Sachbearbeiter: 'Sachbearbeiter', // höchste ebene, darf alles bearbeiten 
	DienstplanerOhneBudget: 'Chef vom Dienst', // dienstplaner + neolohn ohne AGKosten Einsicht
	DienstplanerMitBudget: 'Chef vom Dienst (Budget)', // dienstplaner + neolohn mit AGKosten Einischt
	Dienstplaner: 'Dienstplaner', // nur dienstplaner app
	Mitarbeiter: 'Mitarbeiter', // nur mitarbeiter app
    Zeiterfassung: 'Zeiterfassung', // nur zeiterfassung app
};

// #477 Umbenennung der Rollen, wir behalten die allen um Keycloak Rollennamen zu überschreiben
export const RolesAlt = {
	Sachbearbeiter: 'sachbearbeiter', // höchste ebene, darf alles bearbeiten 
	DienstplanerOhneBudget: 'dienstplanerOhneBudget', // dienstplaner + neolohn ohne AGKosten Einsicht
	DienstplanerMitBudget: 'dienstplanerMitBudget', // dienstplaner + neolohn mit AGKosten Einischt
	Dienstplaner: 'dienstplaner', // nur dienstplaner
	Mitarbeiter: 'mitarbeiter', // nur mitarbeiter app
    Zeiterfassung: 'zeiterfassung', // nur zeiterfassung app
};