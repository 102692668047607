import _ from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';

import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import basedataService from '../services/basedataService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { addInputRow, collectValues, displayArray, displayValues, erstelleMitarbeiterListe, fuelleSelectOptionen } from '../util.js';
import { debounceSpeichereMA, validateBeschaeftigung } from './ma_daten.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereBeschaeftigung = speichereBeschaeftigung;
window.myHandlers.speichereBeschaeftigungsArt = speichereBeschaeftigungsArt;
window.myHandlers.speichereBeschaeftigung = speichereBeschaeftigung;
window.myHandlers.speichereBeschaeftigungsArt = speichereBeschaeftigungsArt;
window.myHandlers.speichereEintrittsdatum = speichereEintrittsdatum;
window.myHandlers.speichereBefristetBis = speichereBefristetBis;
window.myHandlers.neueMAQualifikation = neueMAQualifikation;
window.myHandlers.updateMAQualifikation = updateMAQualifikation;
window.myHandlers.deleteMAQualifikation = deleteMAQualifikation;
window.myHandlers.selectStandardMAQualifikation = selectStandardMAQualifikation;
window.myHandlers.updateMitarbeiterPosten = updateMitarbeiterPosten;
window.myHandlers.updateMitarbeiterEinsatzort = updateMitarbeiterEinsatzort;
window.myHandlers.updateSollstundenMonat = updateSollstundenMonat;
window.myHandlers.updateSollstundenWoche = updateSollstundenWoche;
window.myHandlers.updateSollstundenTag = updateSollstundenTag;
window.myHandlers.updateSolltageWoche = updateSolltageWoche;

/**
 * Bestückt die HTML Oberfläche der Mitarbeiter Beschäftigung.
 * @param {object} aktuellerMA aktuell ausgewählter Mitarbeiter
 * @param {object} unternehmen das unternehmen des Kunden
 */
async function ladeBeschaeftigung(aktuellerMA, unternehmen) {
    if (!_.isEmpty(aktuellerMA)) {
        // Fülle Betriebsstätte Select
        const betriebSelect = document.querySelector('[aria-label="ma-BetriebsstaetteID"]');
        fuelleSelectOptionen(betriebSelect, unternehmen.Betriebsstaette, '_id', 'BetriebsstaetteName');
        // Fülle Beschäftigungsarten Select
        const beschArten = await basedataService.holeBeschaeftigungsartenBasedataProvider();
        const beschSelect = document.querySelector('[aria-label="ma-Beschaeftigungsart"]');
        fuelleSelectOptionen(beschSelect, beschArten, 'BeschID', ['BeschaeftigungsartKurz', 'Personengruppenschluessel']);
        const befristungen = await basedataService.holeBefristungsgrundBasedataProvider();
        const befristungSelect = document.querySelector('[aria-label="ma-Befristungsgrund"]');
        fuelleSelectOptionen(befristungSelect, befristungen, 'BefristungID', 'Kurz');
        const pausenmodelle = unternehmen.Pausenmodelle;
        const pausenmodellSelect = document.querySelector('[aria-label="ma-Pausenmodell"]');
        fuelleSelectOptionen(pausenmodellSelect, pausenmodelle, '_id', 'Bezeichnung', true, 'keins');
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        // Betriebsstätte dynamisch von der Beschäftigung
        const aktuelleBS = unternehmen.Betriebsstaette.find((bs) => bs._id === aktuelleBeschaeftigung.BetriebsstaetteID);
        const htmlParent = document.getElementById('ma-Beschaeftigung');

        htmlParent.querySelector('[aria-label="ma-Personalnummer"]').value = aktuellerMA.Personalien.Personalnummer;
        displayValues(aktuelleBeschaeftigung, '', htmlParent, 'ma-');
        // Zeige die Posten des Mitarbeiters an inklusive checkboxen für die Zuordnung
        const postenListe = htmlParent.querySelector('[aria-label="ma-Posten"]');
        postenListe.innerHTML = '';
        if (aktuelleBS !== undefined) {
            // Zeige nur Posten an, die auch einem Bereich richtig zugeordnet wurden...
            const validePosten = aktuelleBS.Posten.filter((posten) => unternehmen.Bereiche.find((bereich) => bereich._id === posten.BereichID));
            validePosten.sort((a, b) => (a.Posten.toUpperCase().localeCompare(b.Posten.toUpperCase())));
            validePosten.forEach((posten) => {
                if (posten.Verwenden) {
                    const template = document.querySelector('[ma-posten-template]');
                    const neuerPosten = template.content.cloneNode(true).children[0];
                    const bereich = unternehmen.Bereiche.find((b) => b._id === posten.BereichID);
                    const postenBezElem = neuerPosten.querySelector('[aria-label="ma-Posten-Bezeichnung"]');
                    const spanPost = document.createElement('span');
                    spanPost.innerText = posten.Posten;
                    postenBezElem.appendChild(spanPost);
                    const spanBereich = document.createElement('span');
                    spanBereich.innerText = ` (${bereich.Bereich})`;
                    spanBereich.style.fontSize = '12px';
                    postenBezElem.appendChild(spanBereich);
                    neuerPosten.querySelector('[aria-label="ma-Posten-checkbox"]').id = posten._id;
                    const isAktiv = aktuelleBeschaeftigung.Posten.findIndex((b) => b.PostenID === posten._id);
                    if (isAktiv >= 0) {
                        neuerPosten.querySelector('[aria-label="ma-Posten-checkbox"]').checked = true;
                    } else {
                        neuerPosten.querySelector('[aria-label="ma-Posten-checkbox"]').checked = false;
                    }
                    postenListe.appendChild(neuerPosten);
            }
            });
        }
        // Zeige die Einsatzorte des Mitarbeiters an inklusive checkboxen für die Zuordnung
        const einsatzortListe = htmlParent.querySelector('[aria-label="ma-Einsatzorte"]');
        einsatzortListe.innerHTML = '';
        if (aktuelleBS !== undefined) {
            const einsatzorteVerwendet = aktuelleBS.Einsatzorte.filter((eo) => eo.Verwenden);
            einsatzorteVerwendet.sort((a, b) => (a.Bezeichnung.toUpperCase().localeCompare(b.Bezeichnung.toUpperCase())));
            einsatzorteVerwendet.forEach((einsatzort) => {
                const template = document.querySelector('[ma-einsatzorte-template]');
                const newEinsatzort = template.content.cloneNode(true).children[0];
                newEinsatzort.querySelector('[aria-label="ma-Einsatzort-Bezeichnung"]').innerText = einsatzort.Bezeichnung;
                newEinsatzort.querySelector('[aria-label="ma-Einsatzort-checkbox"]').id = einsatzort._id;
                const isAktiv = aktuelleBeschaeftigung.Einsatzorte.findIndex((eo) => eo.EinsatzortID === einsatzort._id);
                if (isAktiv >= 0) {
                    newEinsatzort.querySelector('[aria-label="ma-Einsatzort-checkbox"]').checked = true;
                } else {
                    newEinsatzort.querySelector('[aria-label="ma-Einsatzort-checkbox"]').checked = false;
                }
                einsatzortListe.appendChild(newEinsatzort);
            });
        }
        // Zeige die Qualifikation/Vergütung des Mitarbeiters an
        ladeQualifikationen(aktuelleBeschaeftigung);

        // Zeige die Regelarbeitszeiten des Mitarbeiters an
        const regeltage = aktuelleBeschaeftigung.Regelarbeitszeiten;
        const htmlTage = htmlParent.querySelectorAll('.regeltag');
        htmlTage.forEach((tag, index) => {
            const tagVon = tag.querySelector('[aria-label="regelzeit-von"]');
            const tagBis = tag.querySelector('[aria-label="regelzeit-bis"]');
            tagVon.value = regeltage[index].Von;
            tagBis.value = regeltage[index].Bis;
        });
        validateBeschaeftigung(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Erstellt eine neue Zeile bei den Qualifikationen
 * @param {*} thisElement der Button zum Erstellen einer neuen Qualifikation
 * @param {*} prefix ma-qu- Präfix, damit die util Funktionen wissen, wo was zu tun ist.
 */
async function neueMAQualifikation(thisElement, prefix) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    let aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    const newRow = addInputRow(thisElement, prefix);
    const qualiSelect = newRow.querySelector('[aria-label="ma-qu-QualifikationID"]');
    const qualisVerwendet = stammdatenService.unternehmensobjekt.Qualifikationen.filter((q) => q.Verwenden);
    qualisVerwendet.sort((a, b) => (a.BezeichnungNeutral.toUpperCase().localeCompare(b.BezeichnungNeutral.toUpperCase())));
    fuelleSelectOptionen(qualiSelect, qualisVerwendet, '_id', 'BezeichnungNeutral');
    // Wir fügen eine neue Quali ein...
    const neueQuali = {
        _id: 'updateMe', // Wird im backend automatisch durch eine neue ObjectId ersetzt
        Entlohnung: window.myVars.HideMod ? '' : 'Effektivlohn', // Chef vom Dienst soll keine Entlohnung auswählen
        QualifikationID: stammdatenService.unternehmensobjekt.Qualifikationen[0]._id, // Wir nehmen einfach die erste als Start
        Basisgrundlohn: 0,
        Effektivlohn: 0,
        Verwenden: true,
        GueltigAb: window.myVars.aktuellesMonatsDatum.format('YYYY-MM-DD'),
        IstStandardQualifikation: false
    };
    newRow.id = prefix + neueQuali._id;
    aktuelleBeschaeftigung.Verguetung.push(neueQuali);
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    ladeQualifikationen(aktuelleBeschaeftigung);
    validateBeschaeftigung(stammdatenService.aktuellerMA);
    await erstelleMitarbeiterListe();
}

/**
 * Updated die Qualifikation des Mitarbeiters
 * Falls es sich nicht um Effektivlohn handelt, muss Effektivlohn disabled sein.
 * @param {HTMLElement} thisElement element auf das geklickt wurde
 */
async function updateMAQualifikation(thisElement) {
    // Zeile ist der parent des input elements
    let zeile = thisElement.parentNode;
    // Beim Verwenden Input gibt es noch ein Div zwischen der Zeile und dem Input.
    if (!zeile.classList.contains('ma-qu-zeile')) {
        zeile = zeile.parentNode;
    }
    // Effektivlohnfelder aktivieren/zurücksetzen
    const entlohnungsartInput = zeile.querySelector('[aria-label="ma-qu-Entlohnung"]');
    const effektivLohnInput = zeile.querySelector('[aria-label="ma-qu-Effektivlohn"]');
    const effektivLohnMonatInput = zeile.querySelector('[aria-label="ma-qu-EffektivlohnMonat"]');
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    if (entlohnungsartInput.value !== 'Effektivlohn') {
        effektivLohnInput.disabled = true;
        effektivLohnInput.value = 0;
        effektivLohnMonatInput.value = 0;
    } else {
        effektivLohnInput.disabled = false;
    }
    // Zielbruttofelder aktivieren/zurücksetzen
    const zielbruttoInput = zeile.querySelector('[aria-label="ma-qu-Zielbrutto"]');
    const zielbruttoMonatInput = zeile.querySelector('[aria-label="ma-qu-ZielbruttoMonat"]');
    if (entlohnungsartInput.value !== 'Zielbrutto') {
        zielbruttoInput.disabled = true;
        zielbruttoInput.value = 0;
        zielbruttoMonatInput.value = 0;
    } else {
        zielbruttoInput.disabled = false;
    }
    updateEffektivlohn(aktuelleBeschaeftigung.SollstundenMonat);
    

    // Beteiligung bei Entlohnungswechsel aktivieren/zurücksetzen
    const beteiligung = zeile.querySelector('[aria-label="ma-qu-UmsatzBeteiligungProzent"');
    if (!['Umsatzanteilslohn', 'umsatzorientierterStundenlohn'].includes(entlohnungsartInput.value)) {
        beteiligung.disabled = true;
        beteiligung.value = 0;
    } else {
        beteiligung.disabled = false;
    }
    // Bei Umsatzsanteilslohn gibt es keinen Basisgrundlohn
    const bgl = zeile.querySelector('[aria-label="ma-qu-Basisgrundlohn"]');
    if (['Umsatzanteilslohn'].includes(entlohnungsartInput.value)) {
        bgl.value = 0;
        bgl.disabled = true;
    } else {
        bgl.disabled = false;
    }
	// wir brauchen nur die ID, also schließen wir den prefix (qu-) aus.
	const zeileID = zeile.id.split('-')[2];
    // Falls die ZeileID zu einer VerguetungID passt, können wir einfach die bestehenden Daten updaten.
    const quali = aktuelleBeschaeftigung.Verguetung.find((vg) => vg._id === zeileID);
    if (!quali) {
        // Bezug ist nicht im Mitarbeiter findbar...
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
        return;
    }
    collectValues(quali, 'ma-qu-', zeile);
    // wir gehen sicher, dass der erste des Monats eingeben wurde.
    if (quali.GueltigAb !== '') {
        quali.GueltigAb = dayjs(quali.GueltigAb).startOf('month').format('YYYY-MM-DD');
        if (thisElement.getAttribute('aria-label') === 'ma-qu-GueltigAb') {
            if (thisElement.value.split('-')[2] !== '01') {
                thisElement.value = quali.GueltigAb;
            }
        }
    }
    // falls wir den Verwenden Button benutzt haben, laden wir die Quali neu...
    if (thisElement.type === 'checkbox') {
        // und entfrenen die Standardquali falls deaktiviert
        if (!quali.Verwenden) {
            quali.IstStandardQualifikation = false;
        }
        ladeQualifikationen(aktuelleBeschaeftigung);
    }
	window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

/**
 * Auswahl einer Standardqualifikation eines Mitarbeiters
 * @param {HTMLElement} thisElement 
 * @returns 
 */
async function selectStandardMAQualifikation(thisElement) {
    // Zeile ist der parent des input elements
    const zeile = thisElement.parentNode;
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    // wir brauchen nur die ID, also schließen wir den prefix (qu-) aus.
	const zeileID = zeile.id.split('-')[2];
    // Falls die ZeileID zu einer VerguetungID passt, können wir einfach die bestehenden Daten updaten.
    const quali = aktuelleBeschaeftigung.Verguetung.find((vg) => vg._id === zeileID);
    if (!quali) {
        // Bezug ist nicht im Mitarbeiter findbar...
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Mitarbeiter Speichern!', -1);
        return;
    }
    if (!quali.Verwenden) {
        systemNachrichtService.zeigeKleineNachricht('Bitte nur aktive Qualifikationen als Standard auswählen.', 0);
        return;
    }
    const oldValue = quali.IstStandardQualifikation;
    // alle anderen auf false setzen...
    aktuelleBeschaeftigung.Verguetung.forEach((vg) => vg.IstStandardQualifikation = false);
    // Auswahl togglen
    quali.IstStandardQualifikation = !oldValue;
    zeigeStandardMAQualifikation(aktuelleBeschaeftigung);
    window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}

function zeigeStandardMAQualifikation(aktuelleBeschaeftigung) {
    const htmlArray = [...document.body.querySelectorAll('.ma-qu-zeile:not(.header)')];
    htmlArray.forEach((zeile) => {
        // wir brauchen nur die ID, also schließen wir den prefix (qu-) aus.
        const zeileID = zeile.id.split('-')[2];
        // Falls die ZeileID zu einer VerguetungID passt, können wir einfach die bestehenden Daten updaten.
        const quali = aktuelleBeschaeftigung.Verguetung.find((vg) => vg._id === zeileID);
        const starElement = zeile.querySelector('.ma-qu-standard').children[0];
        starElement.className = quali?.IstStandardQualifikation ? 'bi bi-star-fill' : 'bi bi-star';
    });
}

/**
 * Löscht die ausgewählte Qualifikation aus dem Mitarbeiter.
 * @param {HTMLElement} thisElement Entfernenbutton
 */
async function deleteMAQualifikation(thisElement) {
    const qualiID = thisElement.id;
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
	const qualiIndex = aktuelleBeschaeftigung.Verguetung.findIndex((qu) => qu._id === qualiID);
	if (qualiIndex < 0) {
		// Qualifikation konnte nicht gefunden werden
        systemNachrichtService.zeigeKleineNachricht('Fehler beim Löschen der Qualifikation!', -1);
		return;
	}
	// Qualifikation aus dem Mitarbeiter löschen
	aktuelleBeschaeftigung.Verguetung.splice(qualiIndex, 1);
	await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    validateBeschaeftigung(stammdatenService.aktuellerMitarbeiter);
    ladeQualifikationen(aktuelleBeschaeftigung);
    await erstelleMitarbeiterListe();
}

async function updateSollstundenMonat(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const sollstundenMonat = parseFloat(thisElement.value);
    updateEffektivlohn(sollstundenMonat);
    const sollstundenWoche = Math.round((sollstundenMonat / 4.35) * 100) / 100;
    document.querySelector('[aria-label="ma-SollstundenWoche"]').value = sollstundenWoche;
    if (aktuelleBeschaeftigung.SolltageWoche > 0) {
        const sollstundenTag = Math.round((sollstundenWoche / aktuelleBeschaeftigung.SolltageWoche) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenTag"]').value = sollstundenTag;
    }
    await speichereBeschaeftigung();
}

async function updateSollstundenWoche(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const sollstundenWoche = parseFloat(thisElement.value);
    const sollstundenMonat = Math.round((sollstundenWoche * 4.35) * 100) / 100;
    document.querySelector('[aria-label="ma-SollstundenMonat"]').value = sollstundenMonat;
    updateEffektivlohn(sollstundenMonat);
    document.querySelector('[aria-label="ma-SollstundenWoche"]').value = sollstundenWoche;
    if (aktuelleBeschaeftigung.SolltageWoche > 0) {
        const sollstundenTag = Math.round((sollstundenWoche / aktuelleBeschaeftigung.SolltageWoche) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenTag"]').value = sollstundenTag;
    }
    await speichereBeschaeftigung();
}

async function updateSollstundenTag(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const sollstundenTag = parseFloat(thisElement.value);
    if (aktuelleBeschaeftigung.SolltageWoche > 0) {
        const sollstundenWoche = Math.round((sollstundenTag * aktuelleBeschaeftigung.SolltageWoche) * 100) / 100;
        const sollstundenMonat = Math.round((sollstundenWoche * 4.35) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenWoche"]').value = sollstundenWoche;
        document.querySelector('[aria-label="ma-SollstundenMonat"]').value = sollstundenMonat;
        updateEffektivlohn(sollstundenMonat);
    }
    await speichereBeschaeftigung();
}

async function updateSolltageWoche(thisElement) {
    const aktuelleBeschaeftigung = stammdatenService.aktuellerMitarbeiter.Beschaeftigung[0];
    const solltageWoche = parseFloat(thisElement.value);
    if (aktuelleBeschaeftigung.SollstundenWoche) {
        const sollstundenWoche = aktuelleBeschaeftigung.SollstundenWoche;
        const sollstundenMonat = Math.round((sollstundenWoche * 4.35) * 100) / 100;
        const sollstundenTag = Math.round((sollstundenWoche / solltageWoche) * 100) / 100;
        document.querySelector('[aria-label="ma-SollstundenTag"]').value = sollstundenTag;
        document.querySelector('[aria-label="ma-SollstundenMonat"]').value = sollstundenMonat;
        updateEffektivlohn(sollstundenMonat);
    }
    await speichereBeschaeftigung();
}

function updateEffektivlohn(sollstundenMonat) {
    document.querySelectorAll('[aria-label="ma-qu-EffektivlohnMonat"]').forEach((input) => { input.value = 0; });

    const qualiChecks = Array.from(document.querySelectorAll('[aria-label="ma-qu-Verwenden"]')).filter((checkbox) => checkbox.checked);
    // Wir berechnen nur falls es genau eine aktive Qualifikation gibt pro Zeitraum.
    const zeitraumQualis = qualiChecks.reduce((result, item) => {
        const zeile = item.closest('.ma-qu-zeile');
        // gueltig ab zeitraum
        const zeitraum = zeile.querySelector('[aria-label="ma-qu-GueltigAb"]').value;
        // pro zeitraum ein array...
        const group = result.find(group => group[0].querySelector('[aria-label="ma-qu-GueltigAb"]').value === zeitraum);
        if (group) {
            group.push(zeile);
        } else {
            result.push([zeile]);
        }
        return result;
    }, []);
    // Wir können nur pro Zeitraum ein Effektivlohn Monat berechnen, wenn es auch keine anderen Qualifikationen gibt.
    zeitraumQualis.forEach((zeitraum) => {
        zeitraum.forEach((quali) => {
            if (zeitraum.length === 1) {
                const qualiConflict = qualiChecks.some((qu) => {
                    const zeileConflict = qu.closest('.ma-qu-zeile');
                    return !_.isEqual(zeileConflict.querySelector('[aria-label="ma-qu-QualifikationID"]').value, quali.querySelector('[aria-label="ma-qu-QualifikationID"]').value);
                });
                const entlohnungsartInput = quali.querySelector('[aria-label="ma-qu-Entlohnung"]');
                if (entlohnungsartInput.value === 'Effektivlohn' && !qualiConflict) {
                    const effektivLohnInput = quali.querySelector('[aria-label="ma-qu-Effektivlohn"]');
                    const effektivLohnMonatInput = quali.querySelector('[aria-label="ma-qu-EffektivlohnMonat"]');
                    effektivLohnMonatInput.value = (effektivLohnInput.value * sollstundenMonat).toFixed(2);
                }
                if (entlohnungsartInput.value === 'Zielbrutto' && !qualiConflict) {
                    const zielbruttoInput = quali.querySelector('[aria-label="ma-qu-Zielbrutto"]');
                    const zielbruttoMonatInput = quali.querySelector('[aria-label="ma-qu-ZielbruttoMonat"]');
                    zielbruttoMonatInput.value = (zielbruttoInput.value * sollstundenMonat).toFixed(2);
                }
            }
        });
    });
}

/**
 * Speichert die ausgewählte Beschäftigungsart fügt PGS, BGS, und Sollstunden/-tage ein.
 * @param {HTMLElement} thisElement Select Dropdown der Beschäftigungsarten
 */
async function speichereBeschaeftigungsArt(thisElement) {
    const beschaeftigungID = thisElement.value;
    const beschaeftigungen = await basedataService.holeBeschaeftigungsartenBasedataProvider();
    const beschaeftigung = beschaeftigungen.find((b) => b.BeschID === beschaeftigungID);
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
    aktuelleBeschaeftigung.Beschaeftigungsart = beschaeftigung.BeschID;
    aktuelleBeschaeftigung.Personengruppenschluessel = beschaeftigung.Personengruppenschluessel;
    aktuelleBeschaeftigung.SollstundenTag = beschaeftigung.SollstundenTag;
    aktuelleBeschaeftigung.SollstundenWoche = beschaeftigung.SollstundenWoche;
    aktuelleBeschaeftigung.SollstundenMonat = beschaeftigung.SollstundenMonat;
    aktuelleBeschaeftigung.SolltageWoche = beschaeftigung.SolltageWoche;
    aktuelleBeschaeftigung.Steuer.Steuerberechnung = beschaeftigung.Steuerberechnung;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselKrankenversicherung = beschaeftigung.BGSKV;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselArbeitslosenversicherung = beschaeftigung.BGSAV;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselRentenversicherung = beschaeftigung.BGSRV;
    aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselPflegeversicherung = beschaeftigung.BGSPV;
    await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
    await ladeBeschaeftigung(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
    await erstelleMitarbeiterListe();
}


/**
 * Ladet die Liste der Qualifikationen neu
 * @param {object} aktuelleBeschaeftigung des Mitarbeiters
 */
function ladeQualifikationen(aktuelleBeschaeftigung) {
    const qualis = aktuelleBeschaeftigung.Verguetung;
    const qualisParent = document.querySelector('[aria-label="ma-qu-Verguetung"]');
    displayArray(qualis, 'ma-qu-', qualisParent);
    updateEffektivlohn(aktuelleBeschaeftigung.SollstundenMonat);
    zeigeStandardMAQualifikation(aktuelleBeschaeftigung);
}

/**
 * Speichert das Eintrittsdatum und wendet Unternehmenstandards für Probezeit und Befristung an
 * @param {HTMLElement} thisElement input Eintrittsdatum
 */
async function speichereEintrittsdatum(thisElement) {
    const unternehmen = await stammdatenService.holeUnternehmensdaten();
    const neuesDatum = dayjs(thisElement.value);
    // Probezeit berechnen
    if (unternehmen.AutomatischeProbezeitBis > 0) {
        const eintrittMonatsanfang = neuesDatum.date() == 1;
        const probezeitDatum = eintrittMonatsanfang 
            ? neuesDatum.add(unternehmen.AutomatischeProbezeitBis - 1, 'months').endOf('month').format('YYYY-MM-DD')
            : neuesDatum.add(unternehmen.AutomatischeProbezeitBis, 'months').subtract(1, 'day').format('YYYY-MM-DD');
        document.querySelector('[aria-label="ma-ProbezeitBis"]').value = probezeitDatum;
    }
    // Befristung berechnen
    if (unternehmen.AutomatischeBefristungBis) {
        const eintrittMonatsanfang = neuesDatum.date() == 1;
        const befristetDatum = eintrittMonatsanfang 
            ? neuesDatum.add(unternehmen.AutomatischeBefristungBis - 1, 'months').endOf('month').format('YYYY-MM-DD')
            : neuesDatum.add(unternehmen.AutomatischeBefristungBis, 'months').subtract(1, 'day').format('YYYY-MM-DD');
        document.querySelector('[aria-label="ma-BefristetBis"]').value = befristetDatum;
        document.querySelector('[aria-label="ma-Befristungsgrund"]').value = '18'; // Sachgrundlos bis zu 2 Jahren
    }
    await speichereBeschaeftigung();
}

async function speichereBefristetBis(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const neuesBefristungsDatum = dayjs(thisElement.value);
    const eintrittsDatum = aktuellerMA.Beschaeftigung[0].Eintrittsdatum;
    const diffmonths = Math.trunc(dayjs.duration(neuesBefristungsDatum.diff(eintrittsDatum)).asMonths());
    // Probezeit berechnen (max 6 monate)
    const probezeitBis = dayjs(eintrittsDatum).add(Math.min((diffmonths / 2), 6), 'months').endOf('month').format('YYYY-MM-DD');
    document.querySelector('[aria-label="ma-ProbezeitBis"]').value = probezeitBis;
    await speichereBeschaeftigung();
}

/**
 * Speichert die Mitarbeiter Beschäftigungsdaten aus der Oberfläche.
 * Beim Wechsel der Betriebsstätte wird der boolean betriebWechsel auf true gesetzt.
 * Im Fall, dass der Mitarbeiter den Betrieb wechselt wird HTML Beschäftigung neu bestückt, um Posten und Einsatzorte anzuzeigen.
 * @param {boolean} betriebWechsel bestimmt ob der Mitarbeiter die Betriebsstätte wechselt.
 */
async function speichereBeschaeftigung(betriebWechsel = false) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const htmlParent = document.getElementById('ma-Beschaeftigung');
        collectValues(aktuelleBeschaeftigung, 'ma-', htmlParent);
        aktuellerMA.Personalien.Personalnummer = htmlParent.querySelector('[aria-label="ma-Personalnummer"]').value;
        // Speichere die Regelarbeitszeiten des Mitarbeiters
        const regeltage = aktuelleBeschaeftigung.Regelarbeitszeiten;
        const htmlTage = htmlParent.querySelectorAll('.regeltag');
        htmlTage.forEach((tag, index) => {
            const tagVon = tag.querySelector('[aria-label="regelzeit-von"]');
            const tagBis = tag.querySelector('[aria-label="regelzeit-bis"]');
            regeltage[index].Von = tagVon.value;
            regeltage[index].Bis = tagBis.value;
            // Falls es eine von/bis Zeit gibt, wird der Tag als Regelarbeitstag vermerkt!
            if (tagVon.value !== '' || tagBis.value !== '') {
                aktuelleBeschaeftigung.Regelarbeitstage[index] = true;
            } else {
                aktuelleBeschaeftigung.Regelarbeitstage[index] = false;
            }
        });
        // Wenn wir die Betriebsstätte Wechseln, müssen wir die zugewiesenen Posten resetten, sonst enstehen ungewünschte Sideeffects.
        if (betriebWechsel) {
            aktuelleBeschaeftigung.Posten = [];
        }
        window.myVars.mitarbeiterEditiert = true;
        debounceSpeichereMA(aktuellerMA, betriebWechsel);
        // Falls die Betriebsstätte gewechselt wird, müssen wir die Beschäftigung neu laden.
        if (betriebWechsel) {
            ladeBeschaeftigung(stammdatenService.aktuellerMitarbeiter, stammdatenService.unternehmensobjekt);
        }
    }
}

/**
 * Speichert die neue Zuordnung zu einem Posten ab.
 * @param {HTMLElement} thisElement Checkbox Element der Zuordnung des Postens auf dem geklickt wurde.
 */
async function updateMitarbeiterPosten(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const bIndex = aktuelleBeschaeftigung.Posten.findIndex((b) => b.PostenID === thisElement.id);
        // Wenn es gesetzt wurde fügen wir den Posten dem Mitarbeiter hinzu
        if (thisElement.checked && bIndex < 0) {
            aktuelleBeschaeftigung.Posten.push({ PostenID: thisElement.id });
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else if (!thisElement.checked && bIndex >= 0) {
            // Wir entfernen den Posten aus dem Mitarbeiter Objekt...
            aktuelleBeschaeftigung.Posten.splice(bIndex, 1);
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else {
            // Falls wir Inkonsistenzen haben, speichern wir nicht...
            systemNachrichtService.zeigeKleineNachricht('Posten konnten nicht aktualisiert werden!', -1);
        }
        validateBeschaeftigung(stammdatenService.aktuellerMA);
        await erstelleMitarbeiterListe();
    }
}

/**
 * Speichert die neue Zuordnung zu einem Einsatzort ab.
 * @param {HTMLElement} thisElement Checkbox Element der Zuordnung des Einsatzorts auf dem geklickt wurde.
 */
async function updateMitarbeiterEinsatzort(thisElement) {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const eoIndex = aktuelleBeschaeftigung.Einsatzorte.findIndex((eo) => eo.EinsatzortID === thisElement.id);
        // Wenn es gesetzt wurde fügen wir den Einsatzort dem Mitarbeiter hinzu
        if (thisElement.checked && eoIndex < 0) {
            aktuelleBeschaeftigung.Einsatzorte.push({ EinsatzortID: thisElement.id });
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else if (!thisElement.checked && eoIndex >= 0) {
            // Wir entfernen den Einsatzort aus dem Mitarbeiter Objekt...
            aktuelleBeschaeftigung.Einsatzorte.splice(eoIndex, 1);
            await stammdatenService.updateMitarbeiterdaten(aktuellerMA);
        } else {
            // Falls wir Inkonsistenzen haben, speichern wir nicht...
            systemNachrichtService.zeigeKleineNachricht('Einsatzorte konnten nicht aktualisiert werden!', -1);
        }
        validateBeschaeftigung(stammdatenService.aktuellerMA);
    }
}

export {
    ladeBeschaeftigung
};
