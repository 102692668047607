import stammdatenService from '../services/stammdatenService.js';
import {
    debounce,
} from '../util.js';

const updateDebounceUnternehmen = debounce(async (args) => {
    // call the function you want to debounce
	await stammdatenService.updateUnternehmensdaten(args[0]);
});

export {
	updateDebounceUnternehmen,
};
