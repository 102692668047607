export const MindestlohnHistorie = [
    {
        MindestlohnStunde: 12.82,
        GueltigAb: '2025-01-01',
        GeringfuegigGrenze: 556
    },
    {
        MindestlohnStunde: 12.41,
        GueltigAb: '2024-01-01',
        GeringfuegigGrenze: 538
    },
    {
        MindestlohnStunde: 12.0,
        GueltigAb: '2022-10-01',
        GeringfuegigGrenze: 520
    },
    {
        MindestlohnStunde: 10.45,
        GueltigAb: '2022-07-01',
        GeringfuegigGrenze: 450
    },
    {
        MindestlohnStunde: 9.82,
        GueltigAb: '2022-01-01',
        GeringfuegigGrenze: 450
    },
];

export const LohnartenMILO = [
    1, 2, 3, 4, 22, 62, 102, 103, 105, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 122, 123, 125, 127, 128, 129, 130,
    131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 201, 300, 301, 302, 303, 304, 305, 900, 901, 902, 905, 1000, 1010, 1011, 1012, 1013,
    1020, 1030, 1040, 1052, 1053, 1060, 1070, 1100, 1101, 1110, 1200, 1201, 1202, 1203, 1210, 1220, 1300, 1301, 1310, 1350, 1351, 1360,
    1400, 1401, 1410, 1411, 1420, 1421, 2000, 2001, 2002, 2010, 2011, 2020, 2030, 2031, 2032, 2033, 2040, 2200, 2201, 2250, 2350, 2360,
    2370, 2501, 3300, 3330, 10000, 10010, 10011, 10012, 10013, 10014, 10015, 10016, 10017, 10018, 10019, 10020, 10021, 10022, 10023, 10024, 10025,
    10026, 10027, 10028, 10029, 10030, 10031, 10032, 10033, 10034, 10035, 10036, 10037, 10038, 10039, 10065, 10066,
];
