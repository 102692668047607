import _ from 'underscore';
import dayjs from '../../../shared/services/dayjs.js';
import stammdatenService from './services/stammdatenService.js';
import SystemDialogService from '../../../shared/services/systemDialogService.js';
import { erstelleMitarbeiterListe, getAktuellerZeitraum, getSelectedMitarbeiterIDs, rejectAktionBeiArchivierteMA, toggleLoader } from './util.js';
import systemNachrichtService from './services/systemNachrichtService.js';

window.myHandlers = window.myHandlers || {};
window.myVars = window.myVars || {};
window.myHandlers.getZettelHTML = getZettelHTML;
window.myHandlers.getCSV = getCSV;
window.myHandlers.downloadBerichtePDF = downloadBerichtePDF;
window.myHandlers.prevPage = prevPage;
window.myHandlers.nextPage = nextPage;
window.myHandlers.uploadBerichte = uploadBerichte;
window.myHandlers.createHTMLpopUp = createHTMLpopUp;
window.myHandlers.toggleBerichtHeader = toggleBerichtHeader;

// globale variablen für die Pagination durch Berichte
let currentIndex = 0;
let htmlArray = [];
const betriebsberichteHTML = ['personalkosten-html', 'stundenbereiche-html', 'bereichsauswertung-html', 'einsatzortauswertung-html'];

async function getZettelHTML(zettelType) {
    if (rejectAktionBeiArchivierteMA()) {
		return;
    }
    // active item auswahl
    document.querySelectorAll('.report-item-active').forEach((elem) => elem.classList.remove('report-item-active'));
    document.getElementById(zettelType).classList.add('report-item-active');
    const aktuellerZeitraum = getAktuellerZeitraum();
	// sammle alle angehakten MitarbeiterIDs
	const maList = getSelectedMitarbeiterIDs();
	if (!betriebsberichteHTML.includes(zettelType) && maList.length < 1) {
		await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
		return;
	}
    const result = await stammdatenService.postBerichteMA(maList, aktuellerZeitraum, zettelType.split('-')[0]);
    if (result && result.length > 0) {
       showReportHTML(result);
    } 
}

async function getCSV(csvType) {
    if (rejectAktionBeiArchivierteMA()) {
		return;
    }
    // active item auswahl
    document.querySelectorAll('.report-item-active').forEach((elem) => elem.classList.remove('report-item-active'));
    document.getElementById(csvType).classList.add('report-item-active');
    const aktuellerZeitraum = dayjs(document.getElementById('monats-auswahl').value).format('YYYY-MM-DD');
    // wir nehmen alle angehakten Mitarbeiter, ansonsten den aktuell ausgewählten Mitarbeiter.
    const maList = getSelectedMitarbeiterIDs();
	if (maList.length < 1) {
		await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
		return;
	}
    await stammdatenService.postBerichteCSV(maList, aktuellerZeitraum, csvType.split('-')[0]);
}

function showReportHTML(reports) {
    htmlArray = reports;
    const pagination = document.getElementById('report-pagination');
    // aktuelle Seitenzahl
    currentIndex = 0;
    // Zeige den Lohnzettel im HTML Viewer in der Oberfläche an
    const reportarea = document.getElementById('reportarea');
    reportarea.innerHTML = reports[currentIndex];
    pagination.querySelector('#report-page-start').innerText = currentIndex + 1;
    pagination.querySelector('#report-page-end').innerText = reports.length;
    pagination.classList.remove('hidden');
}

function prevPage() {
    const reportarea = document.getElementById('reportarea');
    currentIndex = currentIndex - 1 < 0 ? 0 : currentIndex - 1;
    reportarea.innerHTML = htmlArray[currentIndex];
    document.querySelector('#report-page-start').innerText = currentIndex + 1;
}

function nextPage() {
    const reportarea = document.getElementById('reportarea');
    currentIndex = currentIndex + 1 >= htmlArray.length ? htmlArray.length - 1 : currentIndex + 1;
    reportarea.innerHTML = htmlArray[currentIndex];
    document.querySelector('#report-page-start').innerText = currentIndex + 1;
}

/**
 * Lädt die Bericht Oberfläche und richtet ggfs. die Event Handlers ein
 * @param {boolean} refreshEventHandlers bestimmt, ob die Event Handler eingespielt werden sollen.
 * Nur sinnvoll wenn die Bericht Seite zum ersten Mal geladen wird, sonst enstehen doppelte EventHandler Aktionen.
 */
async function ladeBerichteMA(refreshEventHandlers = false, monatswechsel = false) {
    const pagination = document.getElementById('report-pagination');
    pagination.classList.add('hidden');
    const reportarea = document.getElementById('reportarea');
    // event listener für pagination mit Pfeiltasten
    if (refreshEventHandlers) {
        reportarea.addEventListener('keyup', (event) => {
            if (event.key === 'ArrowLeft') {
                prevPage();
            }
            if (event.key === 'ArrowRight') {
                nextPage();
            }
        });        
        const uploadHTML = document.getElementById('bericht-upload-zone');
        addDragAndDropEvents(uploadHTML);
    }
    currentIndex = 0;
    htmlArray = [];
    const inputKW = document.getElementById('report-kw');
    inputKW.value = window.myVars.aktuellesMonatsDatum.isoWeek();
    inputKW.max = window.myVars.aktuellesMonatsDatum.endOf('month').isoWeek();
    inputKW.min = window.myVars.aktuellesMonatsDatum.startOf('month').isoWeek();


    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    const auswahl = document.querySelector('.report-item-active')?.id;
    const type = auswahl ? auswahl.split('-')[1] : '';
    if (_.isEmpty(aktuellerMA) && !betriebsberichteHTML.includes(auswahl)) {
        return;
    } else if (!monatswechsel && !_.isEmpty(aktuellerMA) && betriebsberichteHTML.includes(auswahl)) {
        // Betriebsberichte nicht bei MA auswahl laden...
        return;
    };
    reportarea.innerHTML = '';
    switch (type) {
        case 'html':
            await getZettelHTML(auswahl);
            break;
        case 'csv':
            await getCSV(auswahl);
            break;
        default:
            // Nur Lohnabrechnung automatisch laden, wenn es ein Mitarbeiter ist, sonst kommen wir in unnötige Ladezeiten
            const checkedMA = getSelectedMitarbeiterIDs();
            if (checkedMA.length === 1 && !window.myVars.HideMod) {
                await getZettelHTML('lohnabrechnung-html');
                document.getElementById('lohnabrechnung-html').classList.add('.report-item-active');
            }
            break;
    }
}

async function downloadBerichtePDF() {
    // zettelType auswahl
    const auswahl = document.querySelector('.report-item-active').id;
    const zettelType = auswahl.split('-')[0];
    const aktuellerZeitraum = getAktuellerZeitraum();
	const maList = getSelectedMitarbeiterIDs();
    if (!betriebsberichteHTML.includes(auswahl) && _.isEmpty(maList)) {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
        return;
    }
    await stammdatenService.downloadBerichteMA(maList, aktuellerZeitraum, zettelType);
}


/**
 * Lädt die Berichte per fetch und formData hoch
 * @param {HTMLElement} thisElement file input Element
 */
async function uploadBerichte(thisElement) {
    const inputForm = thisElement.closest('form');
    const file = new FormData(inputForm);
    const allowedDataFormats = ['application/pdf'];
    if (!allowedDataFormats.includes(file.get('file-upload').type)) {
        systemNachrichtService.zeigeKleineNachricht('Nur .pdf Dateien sind erlaubt!');
        thisElement.value = '';
        return;
    }
    const betriebID = document.getElementById('menu-betriebsstaette').value;
    if (!betriebID) {
        systemNachrichtService.zeigeKleineNachricht('Bitte wählen Sie eine Betriebsstätte aus!');
        thisElement.value = '';
        return;
    }
    // Standardmäßig aktuellen Zeitraum wählen...
    document.getElementById('berichte-monat-dialog').value = document.getElementById('monats-auswahl').value;
    const input = await SystemDialogService.instance.displayAsync('berichte-probeabrechnung-dialog');
    if (!input.success) {
        thisElement.value = '';
        return;
    }
    file.append('BetriebsstaetteID', betriebID);
    file.append('Zeitraum', dayjs(input.data.Zeitraum).format('YYYY-MM-DD'));
    file.append('Probeabrechnung', input.data.IstProbe);
    try {
        toggleLoader();
        const response = await fetch('/neolohn/api/berichte', {
            method: 'POST',
            body: file
        });
        if (!response.ok) {
            const msg = await response.text();
            systemNachrichtService.zeigeKleineNachricht(msg);
            throw new Error('HTTP Error Status: ', response.status);
        }
        const result = await response.json();
        systemNachrichtService.zeigeKleineNachricht('Upload erfolgerich!', 1);
        toggleLoader();
        await showAbweichungList(result);
        await erstelleMitarbeiterListe(betriebID);
        thisElement.value = '';
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Upload fehlgeschlagen!', -1);
        thisElement.value = '';
        toggleLoader();
    }
}

async function showAbweichungList(liste) {
    const abweichungen = liste.filter((ma) => ma.AbweichungLohnabrechnung);
    const notFound = liste.filter((ma) => !ma.MatchFound);
    if (notFound.length <= 0 && abweichungen.length <= 0) {
        systemNachrichtService.zeigeKleineNachricht('Keine Abweichungen gefunden!', 1);
        return;
    }
    const body = document.getElementById('abweichung-liste-alert-body');
    if (abweichungen.length > 0) {
        const h4 = document.createElement('h4');
        h4.innerText = 'Abweichungen zu neolohn:';
        body.appendChild(h4);
        const p = document.createElement('p');
        p.innerHTML = '<span>Es liegen Abweichungen in der Lohnabrechnung vor, bitte überprüfen Sie die Mitarbeiter Liste nach diesem Symbol: <i class="bi bi-question-circle-fill"></i></span>';
        body.appendChild(p);
        const p2 = document.createElement('p');
        p2.innerText = 'Tipp: Benutzen Sie den Monatsinfo Filter: "abweichende Lohnabrechnung", um alle Mitarbeiter mit Abweichungen anzuzeigen.';
        body.appendChild(p2);
    }
    if (notFound.length > 0) {
        const h4 = document.createElement('h4');
        h4.innerText = 'Unzuordenbare RV-Nummern:';
        body.appendChild(h4);
        const p = document.createElement('p');
        p.innerText = 'Folgende RV-Nummern aus den Lohnabrechnungen konnten keinem Mitarbeiter zugeordnet werden. \nBitte gehen Sie sicher, dass alle Mitarbeiter eine RV-Nummer eingetragen haben und auch berechnet wurden.';
        body.appendChild(p);
        const listHTML = document.createElement('ul');
        notFound.forEach((item) => {
            const li = document.createElement('li');
            li.innerText = `${item.RVNummer ? item.RVNummer : `Nicht gefunden: ${item.errors.join(', ')}`} ${item.SeitePDF != undefined ? `(Seite ${item.SeitePDF})` : '' }`;
            listHTML.appendChild(li);
        });
        body.appendChild(listHTML);
    }
    await SystemDialogService.instance.displayAsync('abweichung-liste-alert-dialog');
    body.innerHTML = '';
}

function addDragAndDropEvents(uploadZone) {
    const start = ['dragover', 'dragenter'];
    const end = ['dragleave', 'dragend'];
    start.forEach((dragEvent) => {
        uploadZone.addEventListener(dragEvent, (event) => {
            event.preventDefault();
            uploadZone.classList.add('drop-zone-dragover');
        });
    });
    end.forEach((dragEvent) => {
        uploadZone.addEventListener(dragEvent, (event) => {
            event.preventDefault();
            uploadZone.classList.remove('drop-zone-dragover');
        });
    });

    uploadZone.addEventListener('drop', async (e) => {
        e.preventDefault();
        const input = uploadZone.querySelector('#input-file-upload');
        input.files = e.dataTransfer.files;
        uploadZone.classList.remove('drop-zone-dragover');
        await uploadBerichte(input);
    });
}

/**
 * Öffnet den aktuellen HTML Bericht in einem neuem popup
 */
function createHTMLpopUp() {
    if (!_.isEmpty(htmlArray)) {
        const getSelectedReport = document.body.querySelector('.report-item-active');
        // Porträit oder Querformat
        const landscape = ['lohnabrechnung', 'Stundenzettel'].includes(getSelectedReport.id) ? 'width=750px, height=1080px,' : 'width=1100px, height=750px,';
        if (window.myVars.popUpBericht) {
            window.myVars.popUpBericht.close();
        }
        window.myVars.popUpBericht = window.open('about:blank', 'popup', `${landscape} toolbar=no, menubar=no, resizable=yes`);
        window.myVars.popUpBericht.document.write(htmlArray[currentIndex]);
        window.myVars.popUpBericht.document.title = getSelectedReport.innerText;
        window.myVars.popUpBericht.focus();
    }
}

/**
 * Klappt Bericht Kategorien ein/aus
 * @param {*} thisElement HTML Header
 */
function toggleBerichtHeader(thisElement) {
    thisElement.classList.toggle('closed-header');
    let tmpSibling = thisElement.nextElementSibling;
    while(tmpSibling && tmpSibling.classList.contains('schriftverkehr-liste-item')) {
        tmpSibling.classList.toggle('hidden');
        tmpSibling = tmpSibling.nextElementSibling;
    }
}

export { ladeBerichteMA };
