export const ZeitTyp = {
	Bedarf: 1,
	Dienstplan: 2,
	EGFZ: 3,
	Zeiterfassung: 4,
	Korrektur: 5,
	Manuell: 6,
	ZeitenImport: 7,
	Vorlage: 8,
	GeloeschtBedarf: -1,
	GeloeschtDienstplan: -2,
	GeloeschtEGFZ: -3,
	GeloeschtZeitErfassung: -4,
	GeloeschtKorrektur: -5,
	GeloeschtManuell: -6,
	GeloeschtZeitenImport: -7,
	GeloeschtVorlage: -8,
};
