import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayArray, neueArrayRow, resetDialogInput } from '../util.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.confirmNeuerEinsatzortDialog = confirmNeuerEinsatzortDialog;
window.myHandlers.neuerEinsatzort = neuerEinsatzort;
window.myHandlers.speichereEinsatzort = speichereEinsatzort;

// Befüllt die Oberfläche der Verwaltung der Einsatzorte pro Betriebstätte
async function ladeEinsatzorte() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const betriebsstaetten = unternehmen.Betriebsstaette;
	if (betriebsstaetten.length === 0) {
		systemNachrichtService.zeigeKleineNachricht('Keine Betriebsstätten vorhanden!', 0);
		return;
	}
	// reset des leeren container content falls es Betriebsstätten gibt.
	document.querySelector('#eo-empty-message').remove();
    betriebsstaetten.forEach((bs) => {
		const template = document.querySelector('[eo-panel-template]');
		const newPanel = template.content.cloneNode(true).children[0];
		newPanel.id = `${bs._id}-panel`;
		newPanel.querySelector('[eo-button]').id = bs._id;
		newPanel.querySelector('[eo-button-header]').innerText = `Betriebsstätte: ${`${bs.BetriebsstaetteName} ${!bs.Aktiv ? '(inaktiv)' : ''}`}`;
		document.querySelector('.main-container').appendChild(newPanel);
		displayArray(bs.Einsatzorte, 'eo-', newPanel, bs._id);
	});
}

function confirmNeuerEinsatzortDialog() {
	SystemDialogService.instance.confirm({
		einsatzort: document.getElementById('neu-einsatzort-name').value,
	});
}

// Click handler für neuen Einsatzort
async function neuerEinsatzort(thisElement) {
	const prefix = 'eo-';
	const bsID = thisElement.id;
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const input = await SystemDialogService.instance.displayAsync('neu-einsatzort-dialog');
	// vermeide leere Einträge oder ungültige Betriebstätte ID
	if (!input.success) {
		return;
	}
	if (input.data.einsatzort === '' || unternehmen.Betriebsstaette.findIndex((bs) => bs._id === bsID) < 0) {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung angegeben.', '0');
		return;
	}
	// Wir schicken den neuen Bezug an den Server
	const result = await stammdatenService.erstelleEinsatzort(input.data.einsatzort, bsID);
	if (!result) {
		return;
	}
	resetDialogInput('neu-einsatzort-dialog');
	// Aktualisierung der Oberfläche
	const bsPanel = document.getElementById(`${bsID}-panel`);
	const template = bsPanel.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = bsPanel.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, result, prefix, bsID);
}

/**
 * Speichert die aktuelle Änderung der bearbeiteten Zeile
 * @param {HTMLElement} thisElement input Feld, das bearbeitet wurde
 */
async function speichereEinsatzort(thisElement) {
	// Zeile ist der parent des input elements
	const zeile = thisElement.parentNode;
	// wir brauchen die einsatzortID und betriebsstätteID, also schließen wir den prefix (eo-) aus.
	const einsatzortID = zeile.id.split('-')[1];
	const bsID = zeile.id.split('-')[2];
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const betrieb = unternehmen.Betriebsstaette.find((bs) => bs._id === bsID);
	if (!betrieb) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	const einsatzort = betrieb.Einsatzorte.find((qu) => qu._id === einsatzortID);
	if (!einsatzort) {
		// Lohnart ist nicht im Unternehmen findbar...
		return;
	}
	collectValues(einsatzort, 'eo-', zeile);
	updateDebounceUnternehmen(unternehmen);
}

export {
    ladeEinsatzorte,
};