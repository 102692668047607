/**
 * Dayjs Library und Plugins
 * Wird nur von anderen modules importiert!
 */

import dayjs from 'dayjs';
import de from 'dayjs/locale/de.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import isoWeek from 'dayjs/plugin/isoWeek.js';
import duration from 'dayjs/plugin/duration.js';
import isBetween from 'dayjs/plugin/isBetween.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import objectSupport from 'dayjs/plugin/objectSupport.js';
import minMax from 'dayjs/plugin/minMax.js';

dayjs.locale(de); // use locale globally
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);
dayjs.extend(minMax);

export default dayjs;
