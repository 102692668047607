import _ from 'underscore';
import basedataService from '../services/basedataService.js';
import { collectValues, displayValues, fuelleSelectOptionen } from '../util.js';
import { debounceSpeichereMA, validatePersonalien } from './ma_daten.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speicherePersonalien = speicherePersonalien;
window.myHandlers.inputIBAN = inputIBAN;


/**
 * Bestückt die HTML Oberfläche der Mitarbeiter Personalien.
 * @param {object} aktuellerMA aktuell ausgewählter Mitarbeiter
 */
async function ladePersonalien(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        const laender = await basedataService.holeLaenderBasedataProvider();
        const geburtSelect = document.querySelector('[aria-label="ma-Geburtsland"]');
        const nationalitaetSelect = document.querySelector('[aria-label="ma-Staatsangehoerigkeit"]');
        fuelleSelectOptionen(geburtSelect, laender, 'Nationenschluessel', 'Staat');
        fuelleSelectOptionen(nationalitaetSelect, laender, 'Nationenschluessel', 'Staatsangehoerigkeit');
        const parentHtml = document.getElementById('ma-Personalien');
        displayValues(aktuellerMA.Personalien, '', parentHtml, 'ma-');
        geburtSelect.value = aktuellerMA.Personalien.Geburtsland?.Nationenschluessel;
        nationalitaetSelect.value = aktuellerMA.Personalien.Staatsangehoerigkeit?.Nationenschluessel;
        validatePersonalien(aktuellerMA);
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

// Onclick Handler für das Speichern von Mitarbeiter Personalien
async function speicherePersonalien() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (_.isEmpty(aktuellerMA)) {
        systemNachrichtService.zeigeKleineNachricht('Kein Mitarbeiter ausgewählt!', 0);
        return;
    }
    const htmlDiv = document.getElementById('ma-Personalien');
    collectValues(aktuellerMA.Personalien, 'ma-', htmlDiv);
    const nationalitaetSelect = document.querySelector('[aria-label="ma-Staatsangehoerigkeit"]');
    const geburtSelect = document.querySelector('[aria-label="ma-Geburtsland"]');
    aktuellerMA.Personalien.Staatsangehoerigkeit.Nationenschluessel = nationalitaetSelect.value;
    if (nationalitaetSelect.selectedIndex >= 0) {
        aktuellerMA.Personalien.Staatsangehoerigkeit.Nationalitaet = nationalitaetSelect.options[nationalitaetSelect.selectedIndex].innerText;
    }
    aktuellerMA.Personalien.Geburtsland.Nationenschluessel = geburtSelect.value;
    if (geburtSelect.selectedIndex >= 0) {
        aktuellerMA.Personalien.Geburtsland.Staat = geburtSelect.options[geburtSelect.selectedIndex].innerText;
    }
    window.myVars.mitarbeiterEditiert = true;
    debounceSpeichereMA(aktuellerMA);
}


/**
 * Forciert Großbuchstaben und Leerzeichen nach jeder 4. Ziffer für die IBAN
 * @param {HTMLElement} thisElement IBAN Input Feld
 */
async function inputIBAN(thisElement) {
    thisElement.value = thisElement.value.toUpperCase();
    thisElement.value = thisElement.value.replaceAll(' ', '').match(/.{1,4}/g).join(' ');
    await speicherePersonalien();
}

export {
    ladePersonalien
};
